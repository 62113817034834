import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

export const checkInIconPath =
    'M9,19v-3H2v-4h7V9l5,5L9,19 M13,2L5,8.667V10h2V9.778l6-5l6,5v10H7V18H5v4h16V8.667L13,2z';

const CheckInIcon = ({ size }) => (
    <SvgIcon name="CheckInIcon" path={checkInIconPath} size={size} />
);
CheckInIcon.displayName = 'CheckInIcon';
CheckInIcon.propTypes = {
    size: PropTypes.number
};

export default CheckInIcon;
