import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import { withStyles } from "@material-ui/core";

import {formatUnixDateTime} from 'helpers/dateHelpers';
import { Category, TypographyText } from "./../index";

import styles from "./WeatherForcastDay.styles";

const WeatherForcastDay = ({ classes, weather = {}, weatherParams = {} }) => {
  return (
    <Category title="Overview">
      <div className={classes.overviewWrapper}>
        <div className={classes.row}>
          <div className={classes.column}>
            <TypographyText>{`${weather.temperature.morning}°C`}</TypographyText>
            <br />
            <TypographyText color="secondary" fontSize="secondary">
              morning
            </TypographyText>
          </div>
          <div className={classes.column}>
            <TypographyText>{`${weather.temperature.day}°C`}</TypographyText>
            <br />
            <TypographyText color="secondary" fontSize="secondary">
              day
            </TypographyText>
          </div>
          <div className={classes.column}>
            <TypographyText>{`${weather.temperature.evening}°C`}</TypographyText>
            <br />
            <TypographyText color="secondary" fontSize="secondary">
              evening
            </TypographyText>
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.column}>
            <TypographyText color="secondary" fontSize="secondary">
              {`sunrise: `}
            </TypographyText>
            <TypographyText fontSize="secondary">
              {weather.sunrise ? formatUnixDateTime(weather.sunrise, 'HH:mm', weatherParams.utcOffset) : '-'}
            </TypographyText>
            <br />
            <TypographyText color="secondary" fontSize="secondary">
              {`sunset: `}
            </TypographyText>
            <TypographyText fontSize="secondary">
              {weather.sunset ? formatUnixDateTime(weather.sunset, 'HH:mm', weatherParams.utcOffset) : '-'}
            </TypographyText>
          </div>
          <div className={classes.column}>
            <TypographyText color="secondary" fontSize="secondary">
              {`rain: `}
            </TypographyText>
            <TypographyText fontSize="secondary">
              {weather.rain ? `${weather.rain}mm` : "-"}
            </TypographyText>
            <br />
            <TypographyText color="secondary" fontSize="secondary">
              {`wind: `}
            </TypographyText>
            <TypographyText fontSize="secondary">
              {weather.wind ? `${weather.wind}m/s` : "-"}
            </TypographyText>
          </div>
        </div>
      </div>
    </Category>
  );
};
WeatherForcastDay.displayName = "WeatherForcastDay";
WeatherForcastDay.propTypes = {
  classes: PropTypes.object,
  weather: PropTypes.shape({
    id: PropTypes.string,
    sunrise: PropTypes.string,
    sunset: PropTypes.string
  }),
  weatherParams: PropTypes.shape({
    utcOffset: PropTypes.string
  })
};
WeatherForcastDay.defaultProps = {
  classes: {}
};
WeatherForcastDay.fragments = {
  weatherForcastDay: gql`
    fragment WeatherForcastDay on WeatherType {
      id
      sunrise
      sunset
      rain
      wind
      temperature {
        morning
        day
        evening
      }
    }
  `
};

export default withStyles(styles)(WeatherForcastDay);
