import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import { pathOr } from "ramda";

import { withStyles } from "@material-ui/core";

import { Error, TypographyText } from "ui";
import { formatDateRange } from "helpers/dateHelpers";

import styles from "./TravelList.styles";

const TravelList = ({ classes, history }) => {
  const { error, data } = useQuery(travelListQuery);

  if (error) {
    return <Error error={error} />;
  }

  return (
    <Fragment>
      <div className={classes.headline}>Deine Reisen</div>
      {pathOr([], ["travels"], data).map(travel => (
        <div
          key={`travel_${travel.id}`}
          className={classes.travelItem}
          onClick={() => history.push(`/travel-list/${travel.id}`)}
        >
        <div className={classes.map}>
        </div>
          <div>
            <TypographyText color="secondary" fontSize="secondary">
              {formatDateRange(travel.startDate, travel.endDate)}
            </TypographyText>
            <div className={classes.title}>{travel.title}</div>
            <TypographyText color="secondary" fontSize="secondary">
              {`Besuchte Länder: ${pathOr([], ["countries"], travel)
                .map(({ name }) => name)
                .join(", ")}`}
            </TypographyText>
          </div>
        </div>
      ))}
    </Fragment>
  );
};
TravelList.displayName = "TravelList";
TravelList.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};
TravelList.defaultProps = {
  classes: {}
};

const travelListQuery = gql`
  query TravelListQuery {
    travels {
      id
      title
      startDate
      endDate
      countries {
        id
        name
      }
      itenerary {
        id
        destinations {
          id
          location {
            latitude
            longitude
          }
        }
      }
    }
  }
`;

export default withRouter(withStyles(styles)(TravelList));
