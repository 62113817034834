export default theme => ({
    // colors
    primaryTextColor: {
        color: theme.palette.common.black
    },
    secondaryTextColor: {
        color: theme.palette.grey[400]
    },

    // sizes
    primaryCopy: {
        fontSize: '0.95rem'
    },
    secondaryCopy: {
        fontSize: '0.8125rem'
    },

    //paragrah
    paragraph: {
        lineHeight: '150%',
        margin: `${theme.spacing(0.5)}px 0 ${theme.spacing()}px 0`
    }
});
