import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";

const styles = () => ({
  fullSizedImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  }
});

const FullSizedImage = ({ classes, imageName }) => {
  try {
    // try loading the image before rendering it to prevent errors when images don't exist
    const imageNameFormatted = imageName.toLowerCase();
    // eslint-disable-next-line no-unused-vars
    const loadedImage = require(`static/images/${imageNameFormatted}`);

    return (
      <img
        className={classes.fullSizedImage}
        src={require(`static/images/${imageNameFormatted}`)}
        alt={imageName}
      />
    );
  } catch (e) {
    console.error(e);
    return null;
  }
};
FullSizedImage.displayName = "FullSizedImage";
FullSizedImage.propTypes = {
  classes: PropTypes.object.isRequired,
  imageName: PropTypes.string.isRequired
};

FullSizedImage.defaultProps = {
  classes: {}
};

export default withStyles(styles)(FullSizedImage);
