import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import { CoffeeIcon, LocationIcon, RamenIcon, RestaurantIcon } from 'ui/icons';
import getLocationLink from 'shared/deep-link/getLocationLink';

import styles from './MealCardHalf.styles';

const typeMap = {
    BREAKFAST: {
        text: 'Breakfast',
        icon: CoffeeIcon
    },
    LUNCH: {
        text: 'Lunch',
        icon: RamenIcon
    },
    DINNER: {
        text: 'Dinner',
        icon: RestaurantIcon
    }
};

const MealCardHalf = props => {
    const { classes, type, info, location } = props;
    if (!typeMap[type]) {
        return null;
    }

    const Icon = typeMap[type].icon;

    return (
        <div className={classes.cardWrapperHalf}>
            <div className={classes.title}>
                <div>{typeMap[type].text}</div>
            </div>
            <div className={classes.iconWrapper}>
                <Icon size={48} />
            </div>
            {!!info && <div className={classes.infoText}>{info}</div>}
            {!!location && !!location.latitude && (
                <div className={classes.link}>
                    <a
                        href={getLocationLink({
                            location: `${location.latitude},${location.longitude}`,
                            title: info
                        })}
                    >
                        <LocationIcon size={20} />
                        <div>Location</div>
                    </a>
                </div>
            )}
        </div>
    );
};
MealCardHalf.displayName = 'MealCardHalf';
MealCardHalf.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['BREAKFAST', 'LUNCH', 'DINNER']),
    info: PropTypes.string,
    location: PropTypes.shape({
        latitude: PropTypes.string,
        longitude: PropTypes.string
    })
};

export default withStyles(styles)(MealCardHalf);
