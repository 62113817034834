export default theme => ({
  carouselWrapper: {
    width: "100%",
    height: "100%",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover"
    }
  },
  navigation: {
    zIndex: 100,
    position: "absolute",
    bottom: 0,
    left: 0,
    height: 48,
    width: "100%",
    boxSizing: "border-box",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    background:
      "linear-gradient(180deg, rgba(0,0,0,0) 0, rgba(0,0,0,0.50) 50%, rgba(0,0,0,1) 100%)"
  },
  step: {
    width: 5,
    height: 5,
    borderRadius: "50%",
    border: `1px solid ${theme.palette.common.white}`,
    marginLeft: theme.spacing(0.5),

    "&:firstChild": {
      marginLeft: 0
    }
  },
  activeStep: {
    backgroundColor: theme.palette.common.white
  }
});
