import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

export const checkOutIconPath =
    'M18,19v-3h-7v-4h7V9l5,5L18,19 M1,8.667V22h16v-4h-2v1.777H3v-10l6-5l6,5V10h2V8.667L9,2L1,8.667z';

const CheckOutIcon = ({ size }) => (
    <SvgIcon name="CheckOutIcon" path={checkOutIconPath} size={size} />
);
CheckOutIcon.displayName = 'CheckOutIcon';
CheckOutIcon.propTypes = {
    size: PropTypes.number
};

export default CheckOutIcon;
