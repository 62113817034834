const getLocationLink = ({ location: { latitude, longitude } }) => {
  const queryParams = [
    {
      key: "api",
      value: 1
    },
    {
      key: "travelmode",
      value: 'driving'
    },
    {
      key: "layer",
      value: 'traffic'
    },
    {
      key: "destination",
      value: `${latitude},${longitude}`
    },
    {
      key: "backurl",
      value: encodeURI(window.location)
    },
    {
      key: "appname",
      value: encodeURI("Spottedkiwi Travel Planner")
    }
  ];
  const queryString = queryParams
    .map(({ key, value }) => `${key}=${value}`)
    .join("&");

  return `https://www.google.com/maps/dir/?${queryString}`;
};

export default getLocationLink;
