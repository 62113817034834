export default theme => ({
  headerWrapper: {
    width: "100%",
    height: "216px",
    overflow: "hidden",
    position: "relative",
    backgroundColor: theme.palette.common.black
  },
  headerRow: {
    zIndex: 100,
    position: "absolute",
    top: 0,
    left: 0,
    height: 48,
    width: "100%",
    boxSizing: "border-box",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.common.white,
    fontSize: "0.875rem",
    background:
      "linear-gradient(180deg, rgba(0,0,0,0.50) 50%, rgba(0,0,0,0) 100%)"
  }
});
