export default theme => ({
  overviewWrapper: {
    width: "100%",
    flexShrink: 0
  },
  row: {
    width: "100%",
    flexShrink: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "stretch",

    "&:nth-child(1)": {
      marginBottom: theme.spacing(2)
    }
  },
  column: {
    minWidth: "33%",
    maxWidth: "100%",
    flexGrow: 1,
    flexShrink: 0,
    textAlign: "center"
  }
});
