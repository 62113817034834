import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import moment from 'moment';
import { pathOr } from 'ramda';

import { withStyles } from '@material-ui/core';

import { TravelContext } from 'src/context';
import { Category, Error, TypographyParagraph, TypographyText } from 'ui';
import TravelDayAccommodation from './TravelDayAccommodation';
import { Header } from 'shared';
import TravelDayImages from './TravelDayImages';
import TravelDayMeals from './TravelDayMeals';
import TravelDayNavigation from './TravelDayNavigation';
import TravelDayTransport from './TravelDayTransport';
import TravelDayWeather from './TravelDayWeather';
import TravelDayActivities from './TravelDayActivities';

import styles from './TravelDay.styles';

const today = moment().format('YYYY-MM-DD');

class TravelDay extends Component {
    static displayName = 'TravelDay';
    static propTypes = {
        classes: PropTypes.object.isRequired
    };
    static defaultProps = {
        classes: {}
    };
    static contextType = TravelContext;

    state = {
        currentTravelDate: null
    };

    setTravelDate = date => {
        this.setState({ currentTravelDate: date });
    };

    componentDidMount() {
        const {
            travel: { startDate, endDate }
        } = this.context;
        let currentTravelDate;

        if (
            moment(today).isSameOrAfter(startDate) &&
            moment(today).isSameOrBefore(endDate)
        ) {
            currentTravelDate = today;
        } else if (moment(today).isBefore(startDate)) {
            currentTravelDate = startDate;
        } else {
            currentTravelDate = endDate;
        }

        this.setState({ currentTravelDate });
    }

    render() {
        const { currentTravelDate } = this.state;
        if (!currentTravelDate) {
            return null;
        }

        const {
            travel: { startDate, endDate }
        } = this.context;
        const { classes } = this.props;

        return (
            <Query
                query={travelDayQuery}
                variables={{ date: currentTravelDate }}
            >
                {({ loading, error, data = {} }) => {
                    if (error) {
                        return <Error error={error} />;
                    }

                    const destinationNames = [];
                    const destinationWeatherData = [];
                    const uniqueDestinationImageIDs = {};
                    const destinationImages = [];

                    pathOr([], ['travelDay', 'destinations'], data).forEach(
                        (destination = {}) => {
                            destinationNames.push(destination.name);
                            destinationWeatherData.push({
                                latitude: destination.location.latitude,
                                longitude: destination.location.longitude,
                                utcOffset: destination.utcOffset
                            });

                            destination.images.forEach(image => {
                                if (!uniqueDestinationImageIDs[image.id]) {
                                    uniqueDestinationImageIDs[image.id] =
                                        image.id;
                                    destinationImages.push(image);
                                }
                            });
                        }
                    );

                    return (
                        <div className={classes.pageWrapper}>
                            <Header
                                headerChildren={
                                    destinationWeatherData.length ? (
                                        <TravelDayWeather
                                            date={currentTravelDate}
                                            weatherParams={
                                                destinationWeatherData
                                            }
                                            locationTitles={destinationNames}
                                        />
                                    ) : null
                                }
                            >
                                <TravelDayImages images={destinationImages} />
                            </Header>
                            <TravelDayNavigation
                                currentTravelDate={currentTravelDate}
                                destinationNames={destinationNames}
                                endDate={endDate}
                                handleDateChange={this.setTravelDate}
                                startDate={startDate}
                            />
                            <TravelDayTransport
                                date={currentTravelDate}
                                WrappedComponent={TravelDayActivities}
                                title="Today's Schedule"
                                activities={pathOr(
                                    [],
                                    ['travelDay', 'activities'],
                                    data
                                )}
                            />

                            {!!pathOr(
                                null,
                                ['travelDay', 'accommodation', 'id'],
                                data
                            ) && (
                                <TravelDayAccommodation
                                    accommodation={data.travelDay.accommodation}
                                />
                            )}
                            <TravelDayMeals
                                meals={pathOr([], ['travelDay', 'meals'], data)}
                            />
                            {!!pathOr(
                                '',
                                ['travelDay', 'description'],
                                data
                            ) && (
                                <Category title="Itinerary">
                                    <div>
                                        {data.travelDay.description
                                            .split('\n')
                                            .map((p, index) => (
                                                <TypographyParagraph
                                                    key={`descriptionParagraph_${index}`}
                                                >
                                                    <TypographyText>
                                                        {p}
                                                    </TypographyText>
                                                </TypographyParagraph>
                                            ))}
                                    </div>
                                </Category>
                            )}
                            <TravelDayActivities
                                title="Optional Activities"
                                activities={pathOr(
                                    [],
                                    ['travelDay', 'optionalActivities'],
                                    data
                                )}
                            />
                        </div>
                    );
                }}
            </Query>
        );
    }
}

const travelDayQuery = gql`
    query TravelDayQuery($date: String!) {
        travelDay(date: $date) {
            id
            date
            accommodation {
                ...TravelDayAccommodation
            }
            activities {
                ...TravelDayActivities
            }
            description
            destinations {
                name
                location {
                    latitude
                    longitude
                }
                utcOffset
                ...DestinationImages
            }
            meals {
                ...TravelDayMeals
            }
            optionalActivities {
                ...TravelDayActivities
            }
        }
    }
    ${TravelDayImages.fragments.images}
    ${TravelDayAccommodation.fragments.accommodation}
    ${TravelDayActivities.fragments.activities}
    ${TravelDayMeals.fragments.meals}
`;

export default withStyles(styles)(TravelDay);
