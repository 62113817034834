export default theme => ({
  hotelWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },
  title: {
    marginBottom: theme.spacing(0.5)
  },
  imageHolder: {
    width: "100px",
    height: "100px",
    borderRadius: theme.spacing(0.5),
    overflow: "hidden",
    marginRight: theme.spacing(1),
    flexGrow: 0,
    flexShrink: 0
  },
  contact: {
    marginLeft: theme.spacing(4),
    "& a": {
      color: "inherit"
    }
  }
});
