import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { isNil, reject, reverse, sortBy } from "ramda";

import { withStyles } from "@material-ui/core";

import WeatherConditionIcon from "./WeatherConditionIcon";

import styles from "./WeatherOverview.styles";

const formatTemperature = (weather = []) => {
  const temps = [];
  let suffix = "";
  let minTemperature = null;
  let maxTemperature = null;

  // get min temperatures of all destinations
  const minTemperatures = reject(
    isNil,
    weather.map(({ temperature }) => temperature && temperature.min)
  );

  // find the minimum of all destinations for that day
  if (minTemperatures.length) {
    minTemperature = Math.min(...minTemperatures);
    temps.push(minTemperature);
  }

  // get max temperatures of all destinations
  const maxTemperatures = reject(
    isNil,
    weather.map(({ temperature }) => temperature && temperature.max)
  );

  if (maxTemperatures.length) {
    // find the maximum of all destinations for that day
    maxTemperature = Math.max(...maxTemperatures);

    if (maxTemperature !== minTemperature) {
      temps.push(maxTemperature);
    }
  }

  if (temps.length) {
    suffix = "°C";
  }

  return temps.join(" - ") + suffix;
};

const getWeatherConditionIcon = (weather = []) => {
  const icons = [];
  weather.forEach(({ weatherCondition }) => {
    if (weatherCondition) {
      const index = icons.findIndex(
        ({ condition }) => condition === weatherCondition
      );
      if (index >= 0) {
        icons[index].count += 1;
      } else {
        icons.push({ condition: weatherCondition, count: 1 });
      }
    }
  });

  if (icons.length) {
    const sortedIcons = reverse(sortBy(item => item.count, icons));
    return sortedIcons[0].condition;
  }

  return null;
};

const WeatherOverview = ({ classes, weather = [] }) => {
  const weatherCondition = getWeatherConditionIcon(weather);

  return weather.length ? (
    <div className={classes.weatherOverviewWrapper}>
      {!!weatherCondition && (
        <WeatherConditionIcon weatherCondition={weatherCondition} />
      )}
      <div>{formatTemperature(weather)}</div>
    </div>
  ) : null;
};
WeatherOverview.displayName = "WeatherOverview";
WeatherOverview.propTypes = {
  classes: PropTypes.object,
  weather: PropTypes.arrayOf(
    PropTypes.shape({
      weatherCondition: PropTypes.string,
      temperature: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number
      })
    })
  )
};
WeatherOverview.defaultProps = {
  classes: {}
};
WeatherOverview.fragments = {
  weatherOverview: gql`
    fragment WeatherOverview on WeatherType {
      weatherCondition
      temperature {
        min
        max
      }
    }
  `
};

export default withStyles(styles)(WeatherOverview);
