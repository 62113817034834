import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { withStyles } from "@material-ui/core";

import styles from "./Typography.styles";

const Text = ({ children, classes, color, fontSize }) => (
  <span
    className={cx(classes[`${color}TextColor`], classes[`${fontSize}Copy`])}
  >
    {children}
  </span>
);
Text.displayName = "Text";
Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  classes: PropTypes.object,
  color: PropTypes.oneOf(["primary", "secondary"]),
  fontSize: PropTypes.oneOf(["primary", "secondary"])
};
Text.defaultProps = {
  classes: {},
  color: "primary",
  fontSize: "primary"
};

export default withStyles(styles)(Text);
