import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

export const rentalCarIconPath =
    'M19,23l-1.5-1.5v-4.6c-0.7-0.2-1.3-0.6-1.8-1.2C15.2,15,15,14.3,15,13.5c0-1,0.3-1.8,1-2.5c0.7-0.7,1.5-1,2.5-1s1.8,0.3,2.5,1c0.7,0.7,1,1.5,1,2.5c0,0.8-0.2,1.4-0.6,2c-0.4,0.6-1,1-1.6,1.3L21,18l-1.5,1.5L21,21L19,23z M18.5,14c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7C19,12.1,18.8,12,18.5,12s-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7S18.2,14,18.5,14z M13,13.4c0-1.8,0.9-3.4,2.3-4.4H5.8l1-3h10.3l0.7,2c0.2,0,0.5,0,0.7,0c0.5,0,1,0.1,1.5,0.2L18.9,5c-0.1-0.3-0.3-0.5-0.5-0.7C18.1,4.1,17.8,4,17.5,4h-11C6.2,4,5.9,4.1,5.6,4.3C5.4,4.5,5.2,4.7,5.1,5L3,11v8c0,0.3,0.1,0.5,0.3,0.7C3.5,19.9,3.7,20,4,20h1c0.3,0,0.5-0.1,0.7-0.3C5.9,19.5,6,19.3,6,19v-1h9.4C13.9,17,13,15.3,13,13.4z M8.6,14.6C8.3,14.9,7.9,15,7.5,15s-0.8-0.1-1.1-0.4S6,13.9,6,13.5s0.1-0.8,0.4-1.1S7.1,12,7.5,12s0.8,0.1,1.1,0.4S9,13.1,9,13.5S8.9,14.3,8.6,14.6z';

const RentalCarIcon = ({ size }) => (
    <SvgIcon name="RentalCarIcon" path={rentalCarIconPath} size={size} />
);
RentalCarIcon.displayName = 'RentalCarIcon';
RentalCarIcon.propTypes = {
    size: PropTypes.number
};

export default RentalCarIcon;
