import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "./SvgIcon";

export const listIconPath =
  "M3,4H7V8H3V4M9,5V7H21V5H9M3,10H7V14H3V10M9,11V13H21V11H9M3,16H7V20H3V16M9,17V19H21V17H9";

const ListIcon = ({ size }) => (
  <SvgIcon name="ListIcon" path={listIconPath} size={size} />
);
ListIcon.displayName = "ListIcon";
ListIcon.propTypes = {
  size: PropTypes.number
};

export default ListIcon;
