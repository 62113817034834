import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import cx from 'classnames';

import { withStyles } from '@material-ui/core';

import {
    AirplaneIcon,
    BusIcon,
    CarIcon,
    FerryIcon,
    MinibusIcon,
    TaxiIcon,
    TicketIcon,
    TrainIcon
} from './../icons';
import { formatDateTime, calculateDuration } from 'helpers/dateHelpers';
import getRouteLink from 'shared/deep-link/getRouteLink';

import styles from './Transport.styles';

const typeIconMap = {
    BUS: BusIcon,
    CAR: CarIcon,
    FERRY: FerryIcon,
    FLIGHT: AirplaneIcon,
    MINIBUS: MinibusIcon,
    TAXI: TaxiIcon,
    TRAIN: TrainIcon
};

/*
    renders a transportCard depending on the given transport type
*/
const Transport = ({
    classes,
    dateTimeFrom,
    dateTimeTo,
    destination,
    destinationFrom,
    destinationTo,
    distance,
    onClick,
    operator,
    statusData,
    title,
    type,
    vehicleNumber,
    ticket = {}
}) => {
    const Icon = typeIconMap[type];
    const duration = calculateDuration(dateTimeFrom, dateTimeTo);

    const hasRouteLink =
        type === 'CAR' && !!destination && !!destination.latitude;

    let PDF;
    const hasTicket = !!ticket && !!ticket.id;

    if (hasTicket) {
        PDF = require(`static/files/${ticket.path}`);
    }

    const TransportCard = (
        <Fragment>
            <div className={classes.title}>
                {!!title ? (
                    <div className={type === 'CAR' ? classes.carTitle : ''}>
                        {title}
                    </div>
                ) : (
                    <Fragment>
                        <div>{destinationFrom}</div>
                        <div>{destinationTo}</div>
                    </Fragment>
                )}
            </div>
            <div className={classes.iconWrapper}>
                {!!Icon && <Icon size={48} />}
            </div>
            <div className={classes.duration}>
                {!!title
                    ? duration
                    : `${formatDateTime(
                          dateTimeFrom,
                          'HH:mm'
                      )} - ${formatDateTime(dateTimeTo, 'HH:mm')}`}
            </div>
            {!!hasTicket ? (
                <div className={classes.linksWrapper}>
                    <div className={classes.link}>
                        <a href={PDF} target="_blank" rel="noopener noreferrer">
                            <TicketIcon size={20} />
                            <div>
                                {!!operator && (
                                    <div className={classes.infoText}>
                                        {operator}
                                    </div>
                                )}
                                {!!vehicleNumber && (
                                    <div className={classes.infoText}>
                                        {vehicleNumber}
                                    </div>
                                )}
                                {type === 'CAR' && !!distance && (
                                    <div
                                        className={classes.infoText}
                                    >{`${distance} km`}</div>
                                )}
                            </div>
                        </a>
                    </div>
                </div>
            ) : (
                <Fragment>
                    {!!operator && (
                        <div className={classes.infoText}>{operator}</div>
                    )}
                    {!!vehicleNumber && (
                        <div className={classes.infoText}>{vehicleNumber}</div>
                    )}
                    {type === 'CAR' && !!distance && (
                        <div
                            className={classes.infoText}
                        >{`${distance} km`}</div>
                    )}
                </Fragment>
            )}
        </Fragment>
    );

    return hasRouteLink ? (
        <div
            className={cx(
                classes.transportWrapper,
                classes[statusData.className]
            )}
            onClick={!!onClick ? onClick : null}
        >
            <a
                href={getRouteLink({ location: destination })}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.transportLink}
            >
                {TransportCard}
            </a>
        </div>
    ) : (
        <div
            className={cx(
                classes.transportWrapper,
                classes[statusData.className]
            )}
            onClick={!!onClick ? onClick : null}
        >
            {TransportCard}
        </div>
    );
};
Transport.displayName = 'Transport';
Transport.propTypes = {
    classes: PropTypes.object,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string,
    dateTimeFrom: PropTypes.string.isRequired,
    dateTimeTo: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    operator: PropTypes.string,
    statusData: PropTypes.shape({
        className: PropTypes.string
    }),
    destinationFrom: PropTypes.string,
    destinationTo: PropTypes.string,
    vehicleNumber: PropTypes.string,
    ticket: PropTypes.shape({
        id: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        description: PropTypes.string
    })
};
Transport.defaultProps = {
    classes: {},
    statusData: {}
};
Transport.fragments = {
    transport: gql`
        fragment Transport on TransportType {
            id
            type
            title
            dateTimeFrom
            dateTimeTo
            operator
            distance
            destination {
                latitude
                longitude
            }
            destinationFrom
            destinationTo
            vehicleNumber
            ticket {
                id
                path
                description
            }
        }
    `
};

export default withStyles(styles)(Transport);
