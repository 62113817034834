import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "./SvgIcon";

export const busIconPath =
  "M18,11H6V6H18M16.5,17A1.5,1.5 0 0,1 15,15.5A1.5,1.5 0 0,1 16.5,14A1.5,1.5 0 0,1 18,15.5A1.5,1.5 0 0,1 16.5,17M7.5,17A1.5,1.5 0 0,1 6,15.5A1.5,1.5 0 0,1 7.5,14A1.5,1.5 0 0,1 9,15.5A1.5,1.5 0 0,1 7.5,17M4,16C4,16.88 4.39,17.67 5,18.22V20A1,1 0 0,0 6,21H7A1,1 0 0,0 8,20V19H16V20A1,1 0 0,0 17,21H18A1,1 0 0,0 19,20V18.22C19.61,17.67 20,16.88 20,16V6C20,2.5 16.42,2 12,2C7.58,2 4,2.5 4,6V16Z";

const BusIcon = ({ size }) => (
  <SvgIcon name="BusIcon" path={busIconPath} size={size} />
);
BusIcon.displayName = "BusIcon";
BusIcon.propTypes = {
  size: PropTypes.number
};

export default BusIcon;
