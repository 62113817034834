import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

export const coffeeIconPath =
    'M2,21H20V19H2M20,8H18V5H20M20,3H4V13A4,4 0 0,0 8,17H14A4,4 0 0,0 18,13V10H20A2,2 0 0,0 22,8V5C22,3.89 21.1,3 20,3Z';

const CoffeeIcon = ({ size }) => (
    <SvgIcon name="CoffeeIcon" path={coffeeIconPath} size={size} />
);
CoffeeIcon.displayName = 'CoffeeIcon';
CoffeeIcon.propTypes = {
    size: PropTypes.number
};

export default CoffeeIcon;
