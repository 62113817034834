import React, { Fragment, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { path } from 'ramda';
import cx from 'classnames';

import { withStyles } from '@material-ui/core';

import { Category, Error, FullSizedImage, TypographyText } from 'ui';
import { SwapIcon } from 'ui/icons';
import { Header } from 'shared';
import { currencyToEuro, euroToCurrency } from './calculation-helpers';

import styles from './CurrencyCalculator.styles';

const CurrencyCalculator = ({ classes = {} }) => {
    const { currencyId } = useParams();

    //  query currency info and conversion rate
    const { error, data = {} } = useQuery(currencyCalculatorQuery, {
        variables: { currencyId }
    });

    // Declare state
    const [
        { direction, valueToCalculate, calculationResult },
        setState
    ] = useState({
        direction: 'currencyToEuro',
        valueToCalculate: null,
        calculationResult: null
    });

    // render an error
    if (error) {
        return <Error error={error} />;
    }

    if (!path(['currency', 'id'], data)) {
        return null;
    }

    // get currency data and set defaults
    const conversionRate = path(['currency', 'conversionRate'], data) || 154;
    const {
        currency: { name, abbreviation }
    } = data;

    const toggleDirection = () => {
        const newDirection =
            direction === 'currencyToEuro'
                ? 'euroToCurrency'
                : 'currencyToEuro';
        setState({
            valueToCalculate,
            calculationResult:
                newDirection === 'currencyToEuro'
                    ? currencyToEuro({
                          amount: valueToCalculate,
                          conversionRate
                      })
                    : euroToCurrency({
                          amount: valueToCalculate,
                          conversionRate
                      }),
            direction: newDirection
        });
    };

    const updateValueToCalculate = event => {
        const val = event.target.value;
        return setState({
            direction,
            valueToCalculate: val,
            calculationResult:
                direction === 'currencyToEuro'
                    ? currencyToEuro({ amount: val, conversionRate })
                    : euroToCurrency({ amount: val, conversionRate })
        });
    };

    return (
        <Fragment>
            <Header>
                <FullSizedImage imageName={`currency_${abbreviation}.jpg`} />
            </Header>
            <Category title={name}>
                <div className={classes.card}>
                    <div className={classes.main}>{`1 ${abbreviation}`}</div>
                    <div>
                        <TypographyText color="secondary">
                            {`${currencyToEuro({
                                amount: 1,
                                conversionRate
                            })} EUR`}
                        </TypographyText>
                    </div>
                </div>
                <div className={classes.card}>
                    <div className={classes.main}>1 EUR</div>
                    <div>
                        <TypographyText color="secondary">
                            {`${euroToCurrency({
                                amount: 1,
                                conversionRate
                            })} ${abbreviation}`}
                        </TypographyText>
                    </div>
                </div>
            </Category>
            <Category title="Calculator">
                <div className={classes.calculatorWrapper}>
                    <div className={classes.calculatorHeader}>
                        <div className={classes.currencyTitle}>
                            {direction === 'currencyToEuro'
                                ? abbreviation
                                : 'EUR'}
                        </div>
                        <div
                            className={classes.toggleIcon}
                            onClick={toggleDirection}
                        >
                            <SwapIcon />
                        </div>
                        <div className={classes.currencyTitle}>
                            {direction === 'currencyToEuro'
                                ? 'EUR'
                                : abbreviation}
                        </div>
                    </div>
                    <div className={classes.calculatorMain}>
                        <input
                            className={cx(
                                classes.calculatorField,
                                classes.input
                            )}
                            type="number"
                            placeholder="Start typing"
                            value={valueToCalculate || ''}
                            onChange={updateValueToCalculate}
                        />
                        <div className={classes.calculatorField}>
                            {calculationResult}
                        </div>
                    </div>
                </div>
            </Category>
        </Fragment>
    );
};
const currencyCalculatorQuery = gql`
    query CurrencyCalculatorQuery($currencyId: ID!) {
        currency(currencyId: $currencyId) {
            id
            name
            abbreviation
            conversionRate
        }
    }
`;

export default withStyles(styles)(CurrencyCalculator);
