export default theme => ({
  weatherOverviewWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",

    "& > *": {
      marginLeft: theme.spacing(1),

      "&:first-child": {
        marginLeft: 0
      }
    }
  }
});
