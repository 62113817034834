import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { path } from 'ramda';

import { withStyles } from '@material-ui/core';

import {
    Category,
    LocationLink,
    TypographyText,
    TypographyParagraph
} from 'ui';

import { ParkingIcon } from '../../ui/icons';

import styles from './TravelDayParking.styles';

const TravelDayParking = ({ parking = {}, classes = {} }) => {
    return (
        <Category title="Parking">
            <div className={classes.parkingWrapper}>
                <div className={classes.icon}>
                    <ParkingIcon />
                </div>
                <div>
                    {!!path(['title'], parking) && (
                        <div className={classes.title}>{parking.title}</div>
                    )}
                    {!!path(['web'], parking) && (
                        <div className={classes.contact}>
                            <TypographyParagraph>
                                <TypographyText
                                    color="secondary"
                                    fontSize="secondary"
                                >
                                    <a href={parking.web}>Website</a>
                                </TypographyText>
                            </TypographyParagraph>
                        </div>
                    )}
                    {!!path(['info'], parking) && (
                        <div className={classes.contact}>
                            <TypographyParagraph>
                                <TypographyText
                                    color="secondary"
                                    fontSize="secondary"
                                >
                                    {parking.info
                                        .split('\n')
                                        .map((row, index) => (
                                            <span key={`info_${index}`}>
                                                {row}
                                                <br />
                                            </span>
                                        ))}
                                </TypographyText>
                            </TypographyParagraph>
                        </div>
                    )}
                    {!!parking.location && (
                        <LocationLink
                            location={parking.location}
                            title="Parking Location"
                        >
                            <div className="address">
                                {parking.address
                                    .split(';')
                                    .map((addressPart, index) => (
                                        <TypographyText
                                            key={`parking_${index}`}
                                            color="secondary"
                                            fontSize="secondary"
                                        >
                                            {addressPart}
                                            <br />
                                        </TypographyText>
                                    ))}
                            </div>
                        </LocationLink>
                    )}
                </div>
            </div>
        </Category>
    );
};
TravelDayParking.displayName = 'TravelDayParking';
TravelDayParking.propTypes = {
    parking: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        info: PropTypes.string,
        address: PropTypes.string,
        location: PropTypes.shape({
            latitude: PropTypes.string,
            longitude: PropTypes.string
        })
    })
};
TravelDayParking.fragments = {
    parking: gql`
        fragment TravelDayParking on ParkingType {
            id
            title
            info
            address
            location {
                latitude
                longitude
            }
            web
        }
    `
};

export default withStyles(styles)(TravelDayParking);
