export default theme => ({
    parkingWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },
    icon: {
        color: theme.palette.common.black,
        marginRight: theme.spacing(3),
        alignSelf: 'center'
    },
    title: {
        marginBottom: theme.spacing(0.5)
    },
    contact: {
        '& a': {
            color: 'inherit'
        }
    }
});
