export default theme => ({
    cardWrapperFull: {
        width: '350px',
        height: '175px',
        flexShrink: 0,
        boxSizing: 'border-box',
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: theme.spacing(0.5),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        marginLeft: theme.spacing(1),
        '&:nth-child(1)': {
            marginLeft: 0
        }
    },
    imageHolder: {
        width: `calc(50% - ${theme.spacing(1)}px)`,
        height: '100%',
        flexShrink: 0
    },
    infoColumn: {
        padding: theme.spacing(1),
        textAlign: 'center',
        flexShrink: 0,
        width: `calc(50% + ${theme.spacing(1)}px)`,
        boxSizing: 'border-box'
    },
    title: {
        height: 40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
            width: '100%',
            fontWeight: 500,
            lineHeight: '1.3rem'
        }
    },
    description: {
        marginTop: theme.spacing(1)
    },
    time: {
        marginTop: theme.spacing(1),
        lineHeight: '1.3rem'
    },
    linksWrapper: {
        width: '100%',
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    link: {
        color: theme.palette.grey[400],
        fontSize: '0.8125rem',
        cursor: 'pointer',

        '& a': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textDecoration: 'none',
            color: theme.palette.grey[400]
        },

        '& svg': {
            marginRight: theme.spacing(0.5)
        }
    }
});
