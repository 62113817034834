export default theme => ({
  navigationRow: {
    width: "100%",
    boxSizing: "border-box",
    padding: `${theme.spacing(2)}px 0`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  navigationButton: {
    padding: `0 ${theme.spacing(2)}px`
  },
  inactiveButton: {
    color: theme.palette.grey[400]
  },
  title: {
    textAlign: "center"
  },
  subTitle: {
    width: "100%",
    paddingBottom: theme.spacing(0.5),
    textAlign: "center",
    fontSize: "0.8125rem",
    color: theme.palette.grey[400]
  }
});
