import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import { FullSizedImage, TypographyText } from 'ui';
import { ExternalLinkIcon, LocationIcon, TicketIcon } from 'ui/icons';
import { formatDateTime } from 'helpers/dateHelpers';
import getLocationLink from 'shared/deep-link/getLocationLink';

import styles from './ActivityCardFull.styles';

const ActivityCardFull = props => {
    const {
        classes,
        dateTime,
        description,
        image = {},
        link,
        location = {},
        name,
        ticket = {},
        ticketLink
    } = props;

    let PDF;
    const hasTicket = !!ticket && !!ticket.id;

    if (hasTicket) {
        PDF = require(`static/files/${ticket.path}`);
    }

    return (
        <div className={classes.cardWrapperFull}>
            {!!image && !!image.id && (
                <div className={classes.imageHolder}>
                    <FullSizedImage imageName={image.path} />
                </div>
            )}
            <div className={classes.infoColumn}>
                <div className={classes.title}>
                    <div>{name}</div>
                </div>
                {!!description && (
                    <div className={classes.description}>
                        {description
                            .split('; ')
                            .map((descriptionPart, index) => (
                                <TypographyText
                                    key={`description_${index}`}
                                    color="secondary"
                                    fontSize="secondary"
                                >
                                    {descriptionPart}
                                    <br />
                                </TypographyText>
                            ))}
                    </div>
                )}
                {hasTicket && !!dateTime && (
                    <div className={classes.time}>
                        {!!dateTime && formatDateTime(dateTime, 'HH:mm')}
                    </div>
                )}
                <div className={classes.linksWrapper}>
                    {hasTicket && (
                        <div className={classes.link}>
                            <a
                                href={PDF}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <TicketIcon size={20} />
                                <div>Ticket</div>
                            </a>
                        </div>
                    )}
                    {!!location && !!location.latitude && (
                        <div className={classes.link}>
                            <a
                                href={getLocationLink({
                                    location: `${location.latitude},${location.longitude}`,
                                    title: name
                                })}
                            >
                                <LocationIcon size={20} />
                                <div>Location</div>
                            </a>
                        </div>
                    )}
                    {!!link && (
                        <div className={classes.link}>
                            <a
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <ExternalLinkIcon size={20} />
                                <div>Link</div>
                            </a>
                        </div>
                    )}
                    {!!ticketLink && (
                        <div className={classes.link}>
                            <a
                                href={ticketLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <TicketIcon size={20} />
                                <div>Ticket</div>
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
ActivityCardFull.displayName = 'ActivityCardFull';
ActivityCardFull.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['VISIT_CULTURAL_SITE', 'HIKE', 'NATURE', 'CITY']),
    name: PropTypes.string,
    description: PropTypes.string,
    dateTimeFrom: PropTypes.string,
    dateTimeTo: PropTypes.string,
    link: PropTypes.string,
    location: PropTypes.shape({
        latitude: PropTypes.string,
        longitude: PropTypes.string
    }),
    ticket: PropTypes.shape({
        id: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        description: PropTypes.string
    }),
    ticketLink: PropTypes.string,
    image: PropTypes.shape({
        id: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        description: PropTypes.string
    })
};

export default withStyles(styles)(ActivityCardFull);
