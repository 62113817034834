import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { isNil } from "ramda";

import { withStyles } from "@material-ui/core";

import { ArrowLeftIcon, ArrowRightIcon } from "ui/icons";

import styles from "./StepNavigation.styles";

const StepNavigation = ({
  classes,
  handleStepChange,
  next,
  prev,
  subtitle,
  title
}) => {
  const hasPrev = !isNil(prev);
  const hasNext = !isNil(next);

  return (
    <div className={classes.navigationRow}>
      <div
        className={cx(classes.switchTravelDayButton, {
          [classes.inactiveButton]: !hasPrev
        })}
        onClick={() => (hasPrev ? handleStepChange(prev) : () => {})}
      >
        <ArrowLeftIcon />
      </div>
      <div>
        <div className={classes.subTitle}>{subtitle}</div>
        <div className={classes.title}>{title}</div>
      </div>
      <div
        className={cx(classes.switchTravelDayButton, {
          [classes.inactiveButton]: !hasNext
        })}
        onClick={() => (hasNext ? handleStepChange(next) : () => {})}
      >
        <ArrowRightIcon />
      </div>
    </div>
  );
};
StepNavigation.displayName = "StepNavigation";
StepNavigation.propTypes = {
  classes: PropTypes.object,
  handleStepChange: PropTypes.func.isRequired,
  next: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prev: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subtitle: PropTypes.string,
  title: PropTypes.string
};
StepNavigation.defaultProps = {
  classes: {},
  destinationNames: [],
  handleDateChange: () => {}
};

export default withStyles(styles)(StepNavigation);
