export default theme => ({
  headline: {
    fontWeight: "bold",
    fontSize: '1.2rem',
    margin: theme.spacing(2),
  },
  travelItem: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  map: {
    width: '50%',
    height: 150,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 4,
    overflow: 'hidden',
    flexGrow: 0,
    flexShrink: 0,
    marginRight:theme.spacing(2)
  },
  title: {
    fontWeight: "bold",
    margin: [[theme.spacing(0.5), 0, theme.spacing(1), 0]]
  }
});
