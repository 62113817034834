import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import { pathOr } from 'ramda';

import { Error } from "ui";

const today = moment().format("YYYY-MM-DD");

/*
    Queries all travels available to the current user
    and redirects to TravelDay if there is a current trip in progress
    or to TravelList if there is none
*/
const Home = ({ history }) => {
  const { loading, error, data = {} } = useQuery(allTravelsQuery);

  if (error) {
    return <Error error={error} />;
  } else if (!loading) {
    let link = 'travel-list';

    // find a trip that is currently in progress
    const currentTrip = pathOr([], ['travels'], data).find(({ startDate, endDate }) => (
      moment(today).isSameOrAfter(startDate) &&
      moment(today).isSameOrBefore(endDate)
    ))

    if(currentTrip) {
      link = `${link}/${currentTrip.id}`;
    }

    // redirect
    history.replace(link);
  }

  return null;
};
Home.displayName = "Home";
Home.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

const allTravelsQuery = gql`
  query TravelQuery {
    travels {
      id
      startDate
      endDate
    }
  }
`;

export default withRouter(Home);
