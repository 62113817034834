import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import { ImageCarousel } from "ui";

const TravelDayImages = ({ images }) => {
  return <ImageCarousel images={images} />;
};
TravelDayImages.displayName = "TravelDayImages";
TravelDayImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  )
};
TravelDayImages.fragments = {
  images: gql`
    fragment DestinationImages on DestinationType {
      images {
        id
        path
        description
      }
    }
  `
};

export default TravelDayImages;
