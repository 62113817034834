import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import styles from './Category.styles';

/*
    renders a category with title and flex row of given children
*/
const Category = ({ children, classes, id, title }) => {
    return (
        <div className={classes.categoryWrapper} key={id}>
            <div className={classes.title}>{title}</div>
            <div className={classes.row}>{children}</div>
        </div>
    );
};
Category.displayName = 'Category';
Category.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    id: PropTypes.string,
    title: PropTypes.string.isRequired
};
Category.defaultProps = {
    classes: {},
    title: ''
};

export default withStyles(styles)(Category);
