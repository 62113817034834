import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { pathOr } from "ramda";
import moment from "moment";

import {
  PopOver,
  StepNavigation,
  WeatherChart,
  WeatherForcastDay,
  WeatherOverview
} from "ui";

const TravelDayWeather = ({
  children,
  classes,
  date,
  locationTitles,
  weatherParams
}) => {
  const { data = {} } = useQuery(travelDayWeatherQuery, {
    variables: { date, weatherParams: weatherParams.map(({ latitude, longitude }) => ({ latitude, longitude })) }
  });

  const [{ anchorElement, currentWeatherLocation }, updateState] = useState({
    anchorElement: null,
    currentWeatherLocation: 0
  });

  const toggleWeatherDetails = event =>
    updateState(
      anchorElement
        ? { anchorElement: null, currentWeatherLocation: 0 }
        : { anchorElement: event.currentTarget, currentWeatherLocation }
    );

  const setCurrentWeatherLocation = index =>
    updateState({ anchorElement, currentWeatherLocation: index });

  return pathOr([], ["weather"], data).length ? (
    <Fragment>
      <div onClick={toggleWeatherDetails}>
        <WeatherOverview weather={data.weather} />
      </div>
      <PopOver
        isOpen={!!anchorElement}
        anchorEl={anchorElement}
        title="Weather Forcast"
        togglePopOver={toggleWeatherDetails}
      >
        <StepNavigation
          handleStepChange={setCurrentWeatherLocation}
          prev={currentWeatherLocation > 0 ? currentWeatherLocation - 1 : null}
          next={
            currentWeatherLocation < data.weather.length - 1
              ? currentWeatherLocation + 1
              : null
          }
          title={locationTitles[currentWeatherLocation]}
          subtitle={moment(date).format("ddd, DD. MMM YYYY")}
        />
        <WeatherForcastDay weather={data.weather[currentWeatherLocation]} weatherParams={weatherParams[currentWeatherLocation]} />
        <WeatherChart weather={data.weather[currentWeatherLocation]} weatherParams={weatherParams[currentWeatherLocation]}  />
      </PopOver>
    </Fragment>
  ) : null;
};
TravelDayWeather.displayName = "TravelDayWeather";
TravelDayWeather.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  date: PropTypes.string,
  weatherParams: PropTypes.arrayOf(
    PropTypes.shape({
      locationId: PropTypes.string,
      utcOffset: PropTypes.string
    })
  ),
  locationTitles: PropTypes.arrayOf(PropTypes.string)
};
TravelDayWeather.defaultProps = {
  classes: {},
  weatherIds: []
};

const travelDayWeatherQuery = gql`
  query TravelDayWeatherQuery(
    $date: String!
    $weatherParams: [WeatherParamsType]!
  ) {
    weather(date: $date, weatherParams: $weatherParams) {
      id
      ...WeatherOverview
      ...WeatherForcastDay
      ...WeatherChart
    }
  }
  ${WeatherOverview.fragments.weatherOverview}
  ${WeatherForcastDay.fragments.weatherForcastDay}
  ${WeatherChart.fragments.weatherChart}
`;

export default TravelDayWeather;
