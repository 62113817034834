import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

export const meetingIconPath =
    'M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z';

const MeetingIcon = ({ size }) => (
    <SvgIcon name="MeetingIcon" path={meetingIconPath} size={size} />
);
MeetingIcon.displayName = 'MeetingIcon';
MeetingIcon.propTypes = {
    size: PropTypes.number
};

export default MeetingIcon;
