import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "./SvgIcon";

export const swapIconPath =
  "M21,9L17,5V8H10V10H17V13M7,11L3,15L7,19V16H14V14H7V11Z";

const SwapIcon = ({ size }) => (
  <SvgIcon name="SwapIcon" path={swapIconPath} size={size} />
);
SwapIcon.displayName = "SwapIcon";
SwapIcon.propTypes = {
  size: PropTypes.number
};

export default SwapIcon;
