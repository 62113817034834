import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { path } from 'ramda';

import { withStyles } from '@material-ui/core';

import {
    Category,
    FullSizedImage,
    LocationLink,
    TypographyText,
    TypographyParagraph
} from 'ui';

import TravelDayParking from './TravelDayParking';
import styles from './TravelDayAccommodation.styles';

const TravelDayAccommodation = ({ accommodation = {}, classes = {} }) => {
    const contactOptions = [];

    if (accommodation.email) {
        contactOptions.push(
            <a href={`mailto:${accommodation.email}`}>Email</a>
        );
    }
    if (accommodation.phone) {
        contactOptions.push(<a href={`tel:${accommodation.phone}`}>Phone</a>);
    }
    if (accommodation.web) {
        contactOptions.push(<a href={accommodation.web}>Website</a>);
    }

    return (
        <Fragment>
            <Category title="Accommodation">
                <div className={classes.hotelWrapper}>
                    {!!path(['image', 'id'], accommodation) && (
                        <div className={classes.imageHolder}>
                            <FullSizedImage
                                imageName={accommodation.image.path}
                            />
                        </div>
                    )}
                    <div>
                        <div className={classes.title}>
                            {accommodation.title}
                        </div>
                        {!!accommodation.location && (
                            <LocationLink
                                location={accommodation.location}
                                title={accommodation.title}
                            >
                                <div className="address">
                                    {accommodation.address
                                        .split(';')
                                        .map((addressPart, index) => (
                                            <TypographyText
                                                key={`accommodation_${index}`}
                                                color="secondary"
                                                fontSize="secondary"
                                            >
                                                {addressPart}
                                                <br />
                                            </TypographyText>
                                        ))}
                                </div>
                            </LocationLink>
                        )}
                        {!!contactOptions.length && (
                            <div className={classes.contact}>
                                <TypographyParagraph>
                                    <TypographyText
                                        color="secondary"
                                        fontSize="secondary"
                                    >
                                        {contactOptions.map(
                                            (option, contactOptionKey) => (
                                                <Fragment
                                                    key={`contactOption_${contactOptionKey}`}
                                                >
                                                    {option} &nbsp;{' '}
                                                </Fragment>
                                            )
                                        )}
                                    </TypographyText>
                                </TypographyParagraph>
                            </div>
                        )}
                        {!!accommodation.info && (
                            <div className={classes.contact}>
                                <TypographyParagraph>
                                    <TypographyText
                                        color="secondary"
                                        fontSize="secondary"
                                    >
                                        {accommodation.info}
                                    </TypographyText>
                                </TypographyParagraph>
                            </div>
                        )}
                    </div>
                </div>
            </Category>
            {!!path(['parking', 'id'], accommodation) && (
                <TravelDayParking parking={accommodation.parking} />
            )}
        </Fragment>
    );
};
TravelDayAccommodation.displayName = 'TravelDayAccommodation';
TravelDayAccommodation.propTypes = {
    accommodation: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        address: PropTypes.string,
        location: PropTypes.shape({
            latitude: PropTypes.string,
            longitude: PropTypes.string
        }),
        image: PropTypes.shape({
            id: PropTypes.string,
            path: PropTypes.string,
            description: PropTypes.string
        }),
        info: PropTypes.string,
        web: PropTypes.string,
        phone: PropTypes.string,
        parking: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            info: PropTypes.string,
            address: PropTypes.string,
            location: PropTypes.shape({
                latitude: PropTypes.string,
                longitude: PropTypes.string
            })
        })
    })
};
TravelDayAccommodation.fragments = {
    accommodation: gql`
        fragment TravelDayAccommodation on AccommodationType {
            id
            title
            address
            location {
                latitude
                longitude
            }
            image {
                id
                path
            }
            web
            phone
            email
            info
            parking {
                ...TravelDayParking
            }
        }
        ${TravelDayParking.fragments.parking}
    `
};

export default withStyles(styles)(TravelDayAccommodation);
