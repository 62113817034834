import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { pathOr } from "ramda";

import { withStyles } from "@material-ui/core";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";

import {formatUnixDateTime} from 'helpers/dateHelpers';
import { Category } from "./../index";
import WeatherConditionIcon from "./WeatherConditionIcon";

import styles from "./WeatherChart.styles";

const interval = 3;
const minTime = 0;

// add missing times at the beginning of the chart
const completeChartDataTimes = (chartData) => {
  if (chartData.length) {
    const completedChartData = [...chartData];
    let firstChartTime = chartData[0].time.substr(0, 2) * 1 - interval;

    while(firstChartTime > minTime) {
      completedChartData.unshift({
        time: `${(`0${firstChartTime}`).substr(-2)}:00`,
        temperature: null,
        rain: null,
        weatherCondition: null
      });
      firstChartTime -= interval;
    }

    return completedChartData;
  }

  return chartData;
}

const WeatherConditionDot = ({ cx, index, points }) => {
  const currentCondition = pathOr(
    null,
    [index, "payload", "weatherCondition"],
    points
  );

  return currentCondition ? (
    <WeatherConditionIcon
      weatherCondition={currentCondition.weatherCondition}
      dayTime={currentCondition.dayTime}
      size={20}
      x={cx - 10}
    />
  ) : null;
};

const WeatherChart = ({ classes, weather = {}, weatherParams = {} }) => {
  if (!pathOr([], ["hourlyForcast"], weather).length) {
    return null;
  }

  const chartData = pathOr([], ["hourlyForcast"], weather).map((timeData) => {
    let rain = timeData.rain;

    if (rain === null && timeData.temperature !== null) {
      rain = 0;
    }

    const time = formatUnixDateTime(timeData.time, "HH:mm", weatherParams.utcOffset);

    return {
      time,
      temperature: timeData.temperature,
      rain,
      weatherCondition: timeData.weatherCondition
        ? {
            weatherCondition: timeData.weatherCondition,
            dayTime:
              timeData.time < weather.sunrise || timeData.time > weather.sunset ? "night" : "day"
          }
        : null
    };
  });

  const completedChartData = completeChartDataTimes(chartData);

  const isRaining = chartData.some(time => !!time.rain);

  return (
    <Category title="Hourly Forecast">
      <div className={classes.chart}>
        <ResponsiveContainer height={200}>
          <ComposedChart data={completedChartData}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="time"
              padding={{ left: 10, right: 10 }}
              tickLine={false}
              tickFormatter={val => (val.substr(0, 2) * 1)%2 ? '': val}
              interval={0}
            />
            <YAxis
              allowDecimals={false}
              axisLine={false}
              domain={[
                dataMin => Math.floor(dataMin / 1.5),
                dataMax => Math.ceil(dataMax * 1.2)
              ]}
              tickFormatter={val => `${val}°C`}
              tickLine={false}
              width={35}
              yAxisId="left"
            />
            {isRaining && (
              <YAxis
                allowDecimals={false}
                axisLine={false}
                domain={[0, dataMax => Math.ceil(dataMax * 1.2)]}
                orientation="right"
                tickFormatter={val => `${val}mm`}
                tickLine={false}
                width={40}
                yAxisId="right"
              />
            )}
            {isRaining && (
              <Bar yAxisId="right" dataKey="rain" barSize={10} fill="#4496c9" />
            )}
            <Line
              yAxisId="left"
              type="natural"
              dataKey="temperature"
              stroke="#ed9a2f"
              strokeWidth={2}
            />
            <Line
              yAxisId="left"
              dataKey="temperature"
              strokeWidth={0}
              dot={<WeatherConditionDot />}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Category>
  );
};
WeatherChart.displayName = "WeatherChart";
WeatherChart.propTypes = {
  classes: PropTypes.object,
  weather: PropTypes.shape({
    id: PropTypes.string,
    sunrise: PropTypes.string,
    sunset: PropTypes.string,
    hourlyForcast: PropTypes.arrayOf(PropTypes.shape({
      time: PropTypes.string,
      temperature: PropTypes.number,
      rain: PropTypes.number,
      weatherCondition: PropTypes.string
    }))
  }),
  weatherParams: PropTypes.shape({
    utcOffset: PropTypes.string
  })
};
WeatherChart.defaultProps = {
  classes: {}
};
WeatherChart.fragments = {
  weatherChart: gql`
    fragment WeatherChart on WeatherType {
      hourlyForcast {
        time
        temperature
        rain
        weatherCondition
      }
    }
  `
};

export default withStyles(styles)(WeatherChart);
