import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "./SvgIcon";

export const menuIconPath = "M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z";

const MenuIcon = ({ size }) => (
  <SvgIcon name="MenuIcon" path={menuIconPath} size={size} />
);
MenuIcon.displayName = "MenuIcon";
MenuIcon.propTypes = {
  size: PropTypes.number
};

export default MenuIcon;
