import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "./SvgIcon";

export const arrowRightIconPath =
  "M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z";

const ArrowRightIcon = ({ size }) => (
  <SvgIcon name="ArrowRightIcon" path={arrowRightIconPath} size={size} />
);
ArrowRightIcon.displayName = "ArrowRightIcon";
ArrowRightIcon.propTypes = {
  size: PropTypes.number
};

export default ArrowRightIcon;
