import React from "react";
import PropTypes from "prop-types";

import { Popover as MuiPopOver, withStyles } from "@material-ui/core";

import { CloseIcon } from "./../icons";

import styles from "./PopOver.styles";

const PopOver = ({
  children,
  classes,
  isOpen,
  anchorEl,
  title,
  togglePopOver
}) => {
  return (
    <MuiPopOver
      PaperProps={{ classes: { root: classes.fullSizePaper } }}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={togglePopOver}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center"
      }}
    >
      <div className={classes.titleBar}>
        <div>{title}</div>
        <div onClick={togglePopOver}>
          <CloseIcon />
        </div>
      </div>
      {children}
    </MuiPopOver>
  );
};
PopOver.displayName = "PopOver";
PopOver.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  isOpen: PropTypes.bool,
  anchorEl: PropTypes.object,
  togglePopOver: PropTypes.func
};
PopOver.defaultProps = {
  classes: {},
  togglePopOver: () => {}
};

export default withStyles(styles)(PopOver);
