import moment from 'moment';

export const formatDateTime = (dateTime, format) => {
    if (!dateTime || !format) {
        return null;
    }

    const dateTimeMoment = moment(dateTime);

    // add an hour for daylight savings time
    // if(dateTimeMoment.isDST()) {
    //   dateTimeMoment.add(1, 'hour')
    // }

    const utcOffset = dateTimeMoment.parseZone().utcOffset();

    if (utcOffset) {
        return dateTimeMoment.utcOffset(utcOffset).format(format);
    }

    return dateTimeMoment.format(format);
};

export const formatUnixDateTime = (unix, format, utcOffset) => {
    if (!unix || !format) {
        return null;
    }

    const unixTimeMoment = moment.unix(unix);

    // add an hour for daylight savings time
    if (unixTimeMoment.isDST()) {
        unixTimeMoment.add(1, 'hour');
    }

    if (utcOffset) {
        return unixTimeMoment.utcOffset(utcOffset).format(format);
    }

    return unixTimeMoment.format(format);
};

export const formatDateRange = (dateFrom, dateTo) => {
    const momentFrom = moment(dateFrom);
    const momentTo = moment(dateTo);

    if (!dateFrom || !dateTo) {
        return null;
    }

    if (momentFrom.isSame(momentTo)) {
        return momentFrom.format('DD.MM.YYYY');
    }

    if (momentFrom.isSame(momentTo, 'month')) {
        return `${momentFrom.format('DD.')} - ${momentTo.format('DD.MM.YYYY')}`;
    }

    if (momentFrom.isSame(momentTo, 'year')) {
        return `${momentFrom.format('DD.MM.')} - ${momentTo.format(
            'DD.MM.YYYY'
        )}`;
    }

    return `${momentFrom.format('DD.MM.YYYY')} - ${momentTo.format(
        'DD.MM.YYYY'
    )}`;
};

export const calculateDuration = (from, to) => {
    const durationInMinutes = moment(to).diff(from, 'minutes');

    if (durationInMinutes < 60) {
        return `${durationInMinutes} min`;
    }

    const hours = Math.floor(durationInMinutes / 60);
    const remainingMinutes = durationInMinutes - hours * 60;

    return `${hours < 10 ? `0${hours}` : hours}:${
        remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes
    }h`;
};
