export default theme => ({
  categoryWrapper: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    "&:first-child": {
      borderTop: "none"
    }
  },
  title: {
    fontSize: "0.875rem",
    color: theme.palette.grey[400],
    textTransform: "uppercase"
  },
  row: {
    width: "100%",
    boxSizing: "border-box",
    paddingTop: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    overflowX: "scroll"
  }
});
