export default theme => ({
  locationLink: {
    color: "inherit",
    textDecoration: "none"
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  icon: {
    color: theme.palette.grey[400],
    marginRight: theme.spacing(1)
  }
});
