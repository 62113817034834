import React from 'react';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Login from './routes/login/Login';
import Home from './routes/home/Home';
import TravelIndex from './routes/travel-index/TravelIndex';
import CurrencyCalculator from './routes/currency-calculator/CurrencyCalculator';
import TravelDay from './routes/travel-day/TravelDay';
import TravelList from './routes/travel-list/TravelList';

const apiURL = 'http://app.spottedkiwi.de:4000/graphql';
//const apiURL = 'http://localhost:4000/graphql';

const client = new ApolloClient({
    credentials: 'same-origin',
    uri: apiURL
});

const App = props => {
    return (
        <ApolloProvider client={client}>
            <Router>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/login">
                    <Login />
                </Route>
                <TravelIndex>
                    <Route exact path="/travel-list">
                        <TravelList />
                    </Route>
                    <Route exact path="/travel-list/:id">
                        <TravelDay />
                    </Route>
                    <Route exact path="/currency-calculator/:currencyId">
                        <CurrencyCalculator />
                    </Route>
                </TravelIndex>
            </Router>
        </ApolloProvider>
    );
};

export default App;
