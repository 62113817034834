import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "./SvgIcon";

export const arrowLeftIconPath =
  "M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z";

const ArrowLeftIcon = ({ size }) => (
  <SvgIcon name="ArrowLeftIcon" path={arrowLeftIconPath} size={size} />
);
ArrowLeftIcon.displayName = "ArrowLeftIcon";
ArrowLeftIcon.propTypes = {
  size: PropTypes.number
};

export default ArrowLeftIcon;
