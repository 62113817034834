const getLocationLink = ({ location, title }) => {
    const queryParams = [
        {
            key: 'api',
            value: 1
        },
        {
            key: 'query',
            value: location
        },
        {
            key: 'backurl',
            value: encodeURI(window.location)
        },
        {
            key: 'appname',
            value: encodeURI('Spottedkiwi Travel Planner')
        }
    ];
    const queryString = queryParams
        .map(({ key, value }) => `${key}=${value}`)
        .join('&');

    return `https://www.google.com/maps/search/?${queryString}`;
};

export default getLocationLink;
