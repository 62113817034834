import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";

import styles from "./Typography.styles";

const Paragraph = ({ children, classes }) => (
  <p className={classes.paragraph}>{children}</p>
);
Paragraph.displayName = "Paragraph";
Paragraph.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  classes: PropTypes.object
};
Paragraph.defaultProps = {
  classes: {}
};

export default withStyles(styles)(Paragraph);
