import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { sortBy } from 'ramda';

import { ActivityCardHalf, ActivityCardFull, Category, Transport } from 'ui';
import FlightStatus from './FlightStatus';

const sizeMapping = {
    ACCOMMODATION_CHECK_IN: ActivityCardHalf,
    ACCOMMODATION_CHECK_OUT: ActivityCardHalf,
    CAR_DROP_OFF: ActivityCardHalf,
    CAR_PICK_UP: ActivityCardHalf,
    MEETING: ActivityCardHalf,
    BORDER_CROSSING: ActivityCardHalf,
    VISIT_CULTURAL_SITE: ActivityCardFull,
    HIKE: ActivityCardFull,
    NATURE: ActivityCardFull,
    CITY: ActivityCardFull
};

const TravelDayActivities = ({
    activities = [],
    title,
    transportItems = []
}) => {
    const sortedItems = sortBy(item => item.dateTime || item.dateTimeFrom, [
        ...activities,
        ...transportItems.map(transportItem => ({
            ...transportItem,
            isTransportItem: true
        }))
    ]);

    return sortedItems.length ? (
        <Category
            id={sortedItems.reduce((acc, { id }) => `${acc}${id}`, '')}
            title={title}
        >
            {sortedItems.map(item => {
                if (item.isTransportItem) {
                    return item.type === 'FLIGHT' ? (
                        <FlightStatus key={item.id} {...item} />
                    ) : (
                        <Transport key={item.id} {...item} />
                    );
                } else {
                    const ActivityComponent = sizeMapping[item.type];
                    return <ActivityComponent key={item.id} {...item} />;
                }
            })}
        </Category>
    ) : null;
};
TravelDayActivities.displayName = 'TravelDayActivities';
TravelDayActivities.propTypes = {
    title: PropTypes.string.isRequired,
    activities: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            type: PropTypes.oneOf([
                'ACCOMMODATION_CHECK_IN',
                'ACCOMMODATION_CHECK_OUT',
                'VISIT_CULTURAL_SITE',
                'HIKE',
                'NATURE',
                'CITY',
                'MEETING',
                'BORDER_CROSSING',
                'CAR_PICK_UP',
                'CAR_DROP_OFF'
            ]),
            dateTime: PropTypes.string
        })
    ),
    transportItems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            type: PropTypes.oneOf([
                'BUS',
                'CAR',
                'FERRY',
                'FLIGHT',
                'MINIBUS',
                'TAXI',
                'TRAIN'
            ]),
            dateTimeFrom: PropTypes.string
        })
    )
};
TravelDayActivities.fragments = {
    activities: gql`
        fragment TravelDayActivities on ActivityType {
            id
            type
            name
            description
            dateTime
            location {
                latitude
                longitude
            }
            link
            ticketLink
            image {
                id
                path
                description
            }
            ticket {
                id
                path
                description
            }
        }
    `
};

export default TravelDayActivities;
