import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "./SvgIcon";

export const minibusIconPath =
  "M3,6H16L19,10H21C22.11,10 23,10.89 23,12V15H21A3,3 0 0,1 18,18A3,3 0 0,1 15,15H9A3,3 0 0,1 6,18A3,3 0 0,1 3,15H1V8C1,6.89 1.89,6 3,6M2.5,7.5V10H10.5V7.5H2.5M12,7.5V10H17.14L15.25,7.5H12M6,13.5A1.5,1.5 0 0,0 4.5,15A1.5,1.5 0 0,0 6,16.5A1.5,1.5 0 0,0 7.5,15A1.5,1.5 0 0,0 6,13.5M18,13.5A1.5,1.5 0 0,0 16.5,15A1.5,1.5 0 0,0 18,16.5A1.5,1.5 0 0,0 19.5,15A1.5,1.5 0 0,0 18,13.5Z";

const MinibusIcon = ({ size }) => (
  <SvgIcon name="MinibusIcon" path={minibusIconPath} size={size} />
);
MinibusIcon.displayName = "MinibusIcon";
MinibusIcon.propTypes = {
  size: PropTypes.number
};

export default MinibusIcon;
