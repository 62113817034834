import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import styles from "./Error.styles";

/*
    Generic Error component
*/
const Error = ({ classes, error }) => {
  console.error(error);

  return (
    <div className={classes.error}>
      Sorry, an unexpected error occured. Please try again later.
    </div>
  );
};
Error.displayName = "Error";
Error.propTypes = {
  classes: PropTypes.object,
  error: PropTypes.object
};
Error.defaultProps = {
  classes: {},
  error: {}
};

export default withStyles(styles)(Error);
