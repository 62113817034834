import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "./SvgIcon";

export const taxiIconPath =
  "M5,11L6.5,6.5H17.5L19,11M17.5,16A1.5,1.5 0 0,1 16,14.5A1.5,1.5 0 0,1 17.5,13A1.5,1.5 0 0,1 19,14.5A1.5,1.5 0 0,1 17.5,16M6.5,16A1.5,1.5 0 0,1 5,14.5A1.5,1.5 0 0,1 6.5,13A1.5,1.5 0 0,1 8,14.5A1.5,1.5 0 0,1 6.5,16M18.92,6C18.72,5.42 18.16,5 17.5,5H15V3H9V5H6.5C5.84,5 5.28,5.42 5.08,6L3,12V20A1,1 0 0,0 4,21H5A1,1 0 0,0 6,20V19H18V20A1,1 0 0,0 19,21H20A1,1 0 0,0 21,20V12L18.92,6Z";

const TaxiIcon = ({ size }) => (
  <SvgIcon name="TaxiIcon" path={taxiIconPath} size={size} />
);
TaxiIcon.displayName = "TaxiIcon";
TaxiIcon.propTypes = {
  size: PropTypes.number
};

export default TaxiIcon;
