import { fade } from "@material-ui/core";

export default theme => {
  const styles = {
    statusWrapper: {
      position: "relative"
    },
    info: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      color: theme.palette.grey[400]
    },
    statusRow: {
      width: "100%",
      boxSizing: "border-box",
      padding: [
        [theme.spacing(), theme.spacing(), theme.spacing(2), theme.spacing()]
      ],
      textAlign: "center"
    },
    dataWrapper: {
      width: "100%",
      flexShrink: 0
    },
    row: {
      width: "100%",
      flexShrink: 0,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "stretch",

      "&:nth-child(1)": {
        marginBottom: theme.spacing(2)
      }
    },
    column: {
      minWidth: "33%",
      maxWidth: "100%",
      flexGrow: 1,
      flexShrink: 0,
      textAlign: "center"
    }
  };

  ["error", "warning", "success", "info"].forEach(color => {
    styles[color] = {
      color: theme.palette[color].main,
      backgroundColor: fade(theme.palette[color].main, 0.05)
    };
  });

  return styles;
};
