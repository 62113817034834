export const currencyToEuro = ({ amount, conversionRate }) => {
    return conversionRate
        ? (Math.round((amount / conversionRate) * 100) / 100).toLocaleString(
              'en-US'
          )
        : 'n/a';
};

export const euroToCurrency = ({ amount, conversionRate }) => {
    return conversionRate
        ? (Math.round(amount * conversionRate * 100) / 100).toLocaleString(
              'en-US'
          )
        : 'n/a';
};
