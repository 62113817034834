import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { TravelContext } from 'src/context';
import { Error } from 'ui';
import { SideNavigation } from 'shared';

/*
    Wrapper Component that gets the travel ID from the config,
    queries general travel data about the travel
    and provides that via context to the sub tree
*/
const TravelIndex = props => {
    // Declare state and toggle function for the SideNavigation
    const [isSideNavigationOpen, setIsSideNavigationOpen] = useState(false);
    const toggleSideNavigation = () =>
        setIsSideNavigationOpen(!isSideNavigationOpen);

    const { children } = props;

    //query the travel data
    const { loading, error, data = {} } = useQuery(travelQuery, {
        variables: { id: '6cb732f8668644088793e0a87e721215' }
    });

    if (error) {
        return <Error error={error} />;
    }

    return !loading ? (
        <TravelContext.Provider
            value={{ travel: data.travel, toggleSideNavigation }}
        >
            <SideNavigation
                isOpen={isSideNavigationOpen}
                toggleSideNavigation={toggleSideNavigation}
            />
            {children}
        </TravelContext.Provider>
    ) : null;
};
TravelIndex.displayName = 'TravelIndex';
TravelIndex.propTypes = {
    children: PropTypes.node
};

const travelQuery = gql`
    query TravelQuery($id: ID!) {
        travel(id: $id) {
            id
            startDate
            endDate
            countries {
                id
                name
                languages {
                    id
                    name
                }
                currencies {
                    id
                    abbreviation
                    name
                }
            }
            title
        }
    }
`;

export default TravelIndex;
