import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

const styles = () => ({
  svgIcon: {
    "& path": {
      fill: "currentColor"
    }
  }
});

const SvgIcon = ({ classes, name, path, size, viewBoxSize, x, y }) => (
  <svg
    className={classes.svgIcon}
    version="1.1"
    id={name || "svgIcon"}
    xmlns="http://www.w3.org/2000/svg"
    x={x}
    y={y}
    width={`${size}px`}
    height={`${size}px`}
    viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
  >
    <path d={path} />
  </svg>
);
SvgIcon.displayName = "SvgIcon";
SvgIcon.propTypes = {
  classes: PropTypes.object,
  name: PropTypes.string,
  path: PropTypes.string,
  size: PropTypes.number,
  viewBoxSize: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number
};
SvgIcon.defaultProps = {
  size: 24,
  viewBoxSize: 24,
  x: 0,
  y: 0
};

export default withStyles(styles)(SvgIcon);
