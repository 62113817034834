import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TravelContext } from 'src/context';
import { pathOr, uniqBy } from 'ramda';
import { withRouter } from 'react-router';
import cx from 'classnames';

import { Drawer, MenuItem, ListItemIcon, withStyles } from '@material-ui/core';

import { CurrencyIcon, EarthIcon, ListIcon, PhrasesIcon } from 'ui/icons';

const styles = theme => ({
    active: {
        color: theme.palette.primary.main
    },
    separated: {
        borderBottom: `1px solid ${theme.palette.grey[400]}`
    }
});

const SideNavigation = ({
    classes,
    isOpen,
    history,
    location,
    toggleSideNavigation
}) => {
    const {
        travel: { countries = [], id, title }
    } = useContext(TravelContext);

    // find the languages from all countries and make them unique
    const languages = uniqBy(
        lang => lang.id,
        countries.reduce(
            (accumulator, currentCountry) => [
                ...accumulator,
                ...pathOr([], ['languages'], currentCountry)
            ],
            []
        )
    );

    // find the currencies from all countries and make them unique
    const currencies = uniqBy(
        cur => cur.id,
        countries.reduce(
            (accumulator, currentCurrency) => [
                ...accumulator,
                ...pathOr([], ['currencies'], currentCurrency)
            ],
            []
        )
    ).filter(({ abbreviation }) => abbreviation !== 'EUR');

    return (
        <Drawer anchor="left" open={isOpen} onClose={toggleSideNavigation}>
            <MenuItem
                key={`list`}
                onClick={() => {
                    toggleSideNavigation();
                    history.push('/travel-list');
                }}
                className={classes.separated}
            >
                <ListItemIcon>
                    <ListIcon />
                </ListItemIcon>
                Your Trips
            </MenuItem>
            <MenuItem
                key={`travel`}
                onClick={() => {
                    toggleSideNavigation();
                    history.push(`/travel-list/${id}`);
                }}
                className={cx({
                    [classes.active]: location.pathname === `/travel-list/${id}`
                })}
            >
                <ListItemIcon>
                    <EarthIcon />
                </ListItemIcon>
                {title}
            </MenuItem>
            {!!languages.length && (
                <MenuItem key={`languages`}>
                    <ListItemIcon>
                        <PhrasesIcon />
                    </ListItemIcon>
                    Useful Phrases
                </MenuItem>
            )}
            {languages.map(language => (
                <MenuItem
                    key={`language_${language.id}`}
                    onClick={() => {
                        toggleSideNavigation();
                        history.push(`/travel-phrases/${language.id}`);
                    }}
                    className={cx({
                        [classes.active]:
                            location.pathname ===
                            `/travel-phrases/${language.id}`
                    })}
                >
                    <ListItemIcon />
                    {language.name}
                </MenuItem>
            ))}
            {!!currencies.length && (
                <MenuItem key={`currencies`}>
                    <ListItemIcon>
                        <CurrencyIcon />
                    </ListItemIcon>
                    Currency Calculator
                </MenuItem>
            )}
            {currencies.map(currency => (
                <MenuItem
                    key={`currencies_${currency.id}`}
                    onClick={() => {
                        toggleSideNavigation();
                        history.push(`/currency-calculator/${currency.id}`);
                    }}
                    className={cx({
                        [classes.active]:
                            location.pathname ===
                            `/currency-calculator/${currency.id}`
                    })}
                >
                    <ListItemIcon />
                    {currency.name}
                </MenuItem>
            ))}
        </Drawer>
    );
};
SideNavigation.displayName = 'SideNavigation';
SideNavigation.propTypes = {
    isOpen: PropTypes.bool,
    location: PropTypes.shape({
        pathname: PropTypes.string
    }),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    toggleSideNavigation: PropTypes.func
};
SideNavigation.defaultProps = {
    toggleSideNavigation: () => {}
};

export default withRouter(withStyles(styles)(SideNavigation));
