import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';

export const parkingIconPath =
    'M13.2,11H10V7H13.2A2,2 0 0,1 15.2,9A2,2 0 0,1 13.2,11M13,3H6V21H10V15H13A6,6 0 0,0 19,9C19,5.68 16.31,3 13,3Z';

const ParkingIcon = ({ size }) => (
    <SvgIcon name="ParkingIcon" path={parkingIconPath} size={size} />
);
ParkingIcon.displayName = 'ParkingIcon';
ParkingIcon.propTypes = {
    size: PropTypes.number
};

export default ParkingIcon;
