import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "./SvgIcon";

export const trainIconPath =
  "M12,2C8,2 4,2.5 4,6V15.5A3.5,3.5 0 0,0 7.5,19L6,20.5V21H8.23L10.23,19H14L16,21H18V20.5L16.5,19A3.5,3.5 0 0,0 20,15.5V6C20,2.5 16.42,2 12,2M7.5,17A1.5,1.5 0 0,1 6,15.5A1.5,1.5 0 0,1 7.5,14A1.5,1.5 0 0,1 9,15.5A1.5,1.5 0 0,1 7.5,17M11,10H6V6H11V10M13,10V6H18V10H13M16.5,17A1.5,1.5 0 0,1 15,15.5A1.5,1.5 0 0,1 16.5,14A1.5,1.5 0 0,1 18,15.5A1.5,1.5 0 0,1 16.5,17Z";

const TrainIcon = ({ size }) => (
  <SvgIcon name="TrainIcon" path={trainIconPath} size={size} />
);
TrainIcon.displayName = "TrainIcon";
TrainIcon.propTypes = {
  size: PropTypes.number
};

export default TrainIcon;
