import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { pathOr } from 'ramda';

import { Transport } from 'ui';

const TravelDayTransport = ({ date, WrappedComponent, ...otherProps }) => {
    const { data = {} } = useQuery(transportQuery, {
        variables: { date: date }
    });

    const transportItems = pathOr([], ['transport'], data);

    return <WrappedComponent transportItems={transportItems} {...otherProps} />;
};
TravelDayTransport.displayName = 'TravelDayTransport';
TravelDayTransport.propTypes = {
    date: PropTypes.string.isRequired
};

const transportQuery = gql`
    query TransportQuery($date: String!) {
        transport(date: $date) {
            type
            ...Transport
        }
    }
    ${Transport.fragments.transport}
`;

export default TravelDayTransport;
