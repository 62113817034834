import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "./SvgIcon";

export const airplaneIconPath =
  "M21,16V14L13,9V3.5A1.5,1.5 0 0,0 11.5,2A1.5,1.5 0 0,0 10,3.5V9L2,14V16L10,13.5V19L8,20.5V22L11.5,21L15,22V20.5L13,19V13.5L21,16Z";

const AirplaneIcon = ({ size }) => (
  <SvgIcon name="AirplaneIcon" path={airplaneIconPath} size={size} />
);
AirplaneIcon.displayName = "AirplaneIcon";
AirplaneIcon.propTypes = {
  size: PropTypes.number
};

export default AirplaneIcon;
