import React from 'react';
import PropTypes from 'prop-types';
import Masonry from 'react-mason';
import { withStyles } from '@material-ui/core';

const sortGallery = [
    'landscape',
    'square',
    'portrait',
    'landscape',
    'landscape',
    'square',
    'portrait',
    'portrait',
    'landscape',
    'portrait',
    'square',
    'portrait',
    'landscape',
    'landscape',
    'square',
    'square',
    'landscape',
    'square'
];

const styles = theme => ({
    image: {
        outline: '2px solid #ffffff',
        outlineOffset: '-2px',
        boxSizing: 'border-box'
    },
    landscape: {
        width: '200px',
        height: '100px'
    },
    portrait: {
        width: '100px',
        height: '200px'
    },
    square: {
        width: '100px',
        height: '100px'
    }
});

const MasonryGallery = ({ children, classes }) => {
    const elements = { landscape: [], square: [], portrait: [] };

    // [
    //     'austria',
    //     'belize',
    //     'bolivia',
    //     'cambodia',
    //     'croatia',
    //     'finland',
    //     'germany'
    // ].forEach(el => {
    //     elements.landscape.push(
    //         <img
    //             key={el}
    //             className={`${classes.image} ${classes.landscape}`}
    //             src={require(`static/images/start-screen/landscape/landscape_${el}_01.jpg`)}
    //             alt={el}
    //         />
    //     );
    // });
    // ['england', 'france', 'greece', 'guatemala', 'india'].forEach(el => {
    //     elements.portrait.push(
    //         <img
    //             key={el}
    //             className={`${classes.image} ${classes.portrait}`}
    //             src={require(`static/images/start-screen/portrait/portrait_${el}_01.jpg`)}
    //             alt={el}
    //         />
    //     );
    // });
    // ['italy', 'kenia', 'malaysia', 'mexico', 'myanmar', 'norway'].forEach(
    //     el => {
    //         elements.square.push(
    //             <img
    //                 key={el}
    //                 className={`${classes.image} ${classes.square}`}
    //                 src={require(`static/images/start-screen/square/square_${el}_01.jpg`)}
    //                 alt={el}
    //             />
    //         );
    //     }
    // );

    Object.keys(elements).forEach(imageType => {
        elements[imageType] = elements[imageType]
            .map(a => [Math.random(), a])
            .sort((a, b) => a[0] - b[0])
            .map(a => a[1]);
    });

    return (
        <Masonry>
            {sortGallery.map(imageType => elements[imageType].shift())}
        </Masonry>
    );
};
MasonryGallery.displayName = 'MasonryGallery';
MasonryGallery.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object
};

export default withStyles(styles)(MasonryGallery);
