import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";

import { getLocationLink } from "shared";
import { LocationIcon } from "./../icons";

import styles from "./LocationLink.styles";

const LocationLink = ({ children, classes, location, title }) => {
  return (
    <a
      href={getLocationLink({
        location: `${location.latitude},${location.longitude}`,
        title
      })}
      className={classes.locationLink}
    >
      <div className={classes.wrapper}>
        <div className={classes.icon}>
          <LocationIcon />
        </div>
        <div>{children}</div>
      </div>
    </a>
  );
};
LocationLink.displayName = "LocationLink";
LocationLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  classes: PropTypes.object,
  location: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }),
  title: PropTypes.string
};

export default withStyles(styles)(LocationLink);
