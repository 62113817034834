import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core";

const styles = () => ({
  maxWidthWrapper: {
    width: '1005px',
    height: '100%',
    margin: '0 auto'
  }
});

const MaxWidthWrapper = ({ children, classes }) => {
  return (
    <div className={classes.maxWidthWrapper}>
      {children}
    </div>
  );
}
MaxWidthWrapper.displayName = "MaxWidthWrapper";
MaxWidthWrapper.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
};

export default withStyles(styles)(MaxWidthWrapper);
