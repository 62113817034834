export default theme => ({
  chart: {
    color: theme.palette.grey[700],
    width: "100%",
    flexShrnk: 0,
    "& text": {
      fontSize: "0.8125rem",
      fill: theme.palette.grey[400]
    },
    "& line.recharts-xAxis": {
      fill: "none",
      stroke: theme.palette.grey[400],
      strokeWidth: 1
    },
    "& .recharts-line-dots circle": {
      fill: "#ed9a2f",
      stroke: "none"
    }
  }
});
