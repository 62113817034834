export default theme => ({
  fullSizePaper: {
    width: "100vw",
    height: "100vh",
    maxWidth: "100vw",
    maxHeight: "100vh",
    top: `0 !important`,
    left: `0 !important`,
    margin: 0,
    padding: 0,
    border: "none",
    borderRadius: 0,
    overflow: "hidden"
  },
  titleBar: {
    width: "100%",
    boxSizing: "border-box",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[400]}`
  }
});
