import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { path, pathOr } from "ramda";
import cx from "classnames";

import { withStyles } from "@material-ui/core";

import { Category, PopOver, Transport, TypographyText } from "ui";
import { formatDateTime, calculateDuration } from "helpers/dateHelpers";

import styles from "./FlightStatus.styles";

const formatStringToReadable = string => {
  const words = string.split("_");
  const firstLetterCapitalizedWords = words.map(
    word => `${word.substring(0, 1)}${word.substring(1).toLowerCase()}`
  );
  return firstLetterCapitalizedWords.join(" ");
};

const FlightStatus = ({ classes, ...transportProps }) => {
  const { id, dateTimeFrom, dateTimeTo, vehicleNumber } = transportProps;
  const { data = {} } = useQuery(transportQuery, {
    variables: {
      dateTimeArrival: dateTimeTo,
      dateTimeDeparture: dateTimeFrom,
      flightId: id,
      flightNumber: vehicleNumber
    }
  });

  const statusData = {};
  const hasStatusData = !!path(["flightStatus", "status"], data);

  if (hasStatusData) {
    const {
      flightStatus: { status }
    } = data;

    statusData.title = `${transportProps.destinationFrom} - ${transportProps.destinationTo}`;
    statusData.flightInfo = `${transportProps.operator} - ${transportProps.vehicleNumber}`;
    statusData.status = formatStringToReadable(status);

    if (status === "CANCELED") {
      statusData.className = "error";
    } else if (["DIVERTED", "DELAYED"].includes(status)) {
      statusData.className = "warning";
    } else if (
      [
        "CHECK_IN",
        "BOARDING",
        "GATE_CLOSED",
        "DEPARTED",
        "EN_ROUTE",
        "EXPECTED",
        "APPROACHING",
        "ARRIVED"
      ].includes(status)
    ) {
      statusData.className = "success";
    } else {
      statusData.className = "neutral";
    }
  }

  const [anchorElement, updateState] = useState(null);

  const toggleFlightDetails = event =>
    updateState(anchorElement ? null : event.currentTarget);

  return (
    <Fragment>
      <Transport
        {...transportProps}
        statusData={statusData}
        onClick={e => (hasStatusData ? toggleFlightDetails(e) : null)}
      />
      {hasStatusData && (
        <PopOver
          isOpen={!!anchorElement}
          anchorEl={anchorElement}
          title={statusData.title}
          togglePopOver={toggleFlightDetails}
        >
          <div className={cx(classes.statusRow, classes[statusData.className])}>
            <TypographyText fontSize="secondary">Status</TypographyText>
            <br />
            {formatStringToReadable(path(["flightStatus", "status"], data))}
          </div>
          <Category title="flight">
            <div className={classes.dataWrapper}>
              <TypographyText color="secondary" fontSize="secondary">
                {"Flight Number: "}
              </TypographyText>
              <TypographyText fontSize="secondary">
                {transportProps.vehicleNumber}
              </TypographyText>
              <br />
              <TypographyText color="secondary" fontSize="secondary">
                {"Operator: "}
              </TypographyText>
              <TypographyText fontSize="secondary">
                {transportProps.operator}
              </TypographyText>
              <br />
              <TypographyText color="secondary" fontSize="secondary">
                {"Duration: "}
              </TypographyText>
              <TypographyText fontSize="secondary">
                {calculateDuration(
                  transportProps.dateTimeFrom,
                  transportProps.dateTimeTo
                )}
              </TypographyText>
            </div>
          </Category>
          <Category title="Departure">
            <div className={classes.dataWrapper}>
              <div className={classes.row}>
                <div className={classes.column}>
                  <TypographyText>
                    {formatDateTime(
                      path(
                        ["flightStatus", "departure", "scheduledTimeLocal"],
                        data
                      ),
                      "HH:mm"
                    )}
                  </TypographyText>
                  <br />
                  <TypographyText color="secondary" fontSize="secondary">
                    Scheduled Time
                  </TypographyText>
                </div>
                <div className={classes.column}>
                  <TypographyText>
                    {formatDateTime(
                      path(
                        ["flightStatus", "departure", "actualTimeLocal"],
                        data
                      ),
                      "HH:mm"
                    )}
                  </TypographyText>
                  <br />
                  <TypographyText color="secondary" fontSize="secondary">
                    Estimated Time
                  </TypographyText>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.column}>
                  <TypographyText>
                    {pathOr(
                      "-",
                      ["flightStatus", "departure", "terminal"],
                      data
                    )}
                  </TypographyText>
                  <br />
                  <TypographyText color="secondary" fontSize="secondary">
                    Terminal
                  </TypographyText>
                </div>
                <div className={classes.column}>
                  <TypographyText>
                    {pathOr("-", ["flightStatus", "departure", "gate"], data)}
                  </TypographyText>
                  <br />
                  <TypographyText color="secondary" fontSize="secondary">
                    Gate
                  </TypographyText>
                </div>
                <div className={classes.column}>
                  <TypographyText>
                    {pathOr(
                      "-",
                      ["flightStatus", "departure", "checkInDesk"],
                      data
                    )}
                  </TypographyText>
                  <br />
                  <TypographyText color="secondary" fontSize="secondary">
                    CheckIn Desk
                  </TypographyText>
                </div>
              </div>
            </div>
          </Category>
          <Category title="Arrival">
            <div className={classes.dataWrapper}>
              <div className={classes.row}>
                <div className={classes.column}>
                  <TypographyText>
                    {formatDateTime(
                      path(
                        ["flightStatus", "arrival", "scheduledTimeLocal"],
                        data
                      ),
                      "HH:mm"
                    )}
                  </TypographyText>
                  <br />
                  <TypographyText color="secondary" fontSize="secondary">
                    Scheduled Time
                  </TypographyText>
                </div>
                <div className={classes.column}>
                  <TypographyText>
                    {formatDateTime(
                      path(
                        ["flightStatus", "arrival", "actualTimeLocal"],
                        data
                      ),
                      "HH:mm"
                    )}
                  </TypographyText>
                  <br />
                  <TypographyText color="secondary" fontSize="secondary">
                    Estimated Time
                  </TypographyText>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.column}>
                  <TypographyText>
                    {pathOr("-", ["flightStatus", "arrival", "terminal"], data)}
                  </TypographyText>
                  <br />
                  <TypographyText color="secondary" fontSize="secondary">
                    Terminal
                  </TypographyText>
                </div>
                <div className={classes.column}>
                  <TypographyText>
                    {pathOr("-", ["flightStatus", "arrival", "gate"], data)}
                  </TypographyText>
                  <br />
                  <TypographyText color="secondary" fontSize="secondary">
                    Gate
                  </TypographyText>
                </div>
                <div className={classes.column}>
                  <TypographyText>
                    {pathOr(
                      "-",
                      ["flightStatus", "arrival", "baggageBelt"],
                      data
                    )}
                  </TypographyText>
                  <br />
                  <TypographyText color="secondary" fontSize="secondary">
                    Baggage Belt
                  </TypographyText>
                </div>
              </div>
            </div>
          </Category>
        </PopOver>
      )}
    </Fragment>
  );
};
FlightStatus.displayName = "FlightStatus";
FlightStatus.propTypes = {
  classes: PropTypes.object,
  dateTimeFrom: PropTypes.string.isRequired,
  vehicleNumber: PropTypes.string.isRequired
};

const transportQuery = gql`
  query FlightStatusQuery(
    $flightId: String!
    $flightNumber: String!
    $dateTimeArrival: String!
    $dateTimeDeparture: String!
  ) {
    flightStatus(
      flightId: $flightId
      flightNumber: $flightNumber
      dateTimeArrival: $dateTimeArrival
      dateTimeDeparture: $dateTimeDeparture
    ) {
      id
      status
      lastUpdated
      departure {
        actualTimeLocal
        checkInDesk
        gate
        scheduledTimeLocal
        terminal
      }
      arrival {
        actualTimeLocal
        baggageBelt
        gate
        scheduledTimeLocal
        terminal
      }
    }
  }
`;

export default withStyles(styles)(FlightStatus);
