import React from "react";
import { MasonryGallery, MaxWidthWrapper } from 'ui';

const Login = ({ classes }) => {

  const testData = {
    landscape: [
      'landscape_austria_01.jpg',
      'landscape_austria_02.jpg',
      'landscape_belize_01.jpg',
      'landscape_belize_02.jpg',
      'landscape_bolivia_01.jpg',
      'landscape_cambodia_01.jpg',
      'landscape_croatia_01.jpg',
      'landscape_croatia_02.jpg',
      'landscape_croatia_03.jpg',
      'landscape_croatia_04.jpg',
      'landscape_croatia_05.jpg',
      'landscape_croatia_06.jpg',
      'landscape_finland_01.jpg',
      'landscape_germany_01.jpg',
      'landscape_germany_02.jpg',
      'landscape_germany_03.jpg',
      'landscape_germany_04.jpg',
      'landscape_germany_05.jpg',
      'landscape_greece_01.jpg',
      'landscape_greece_02.jpg',
      'landscape_greece_03.jpg',
      'landscape_india_01.jpg',
      'landscape_india_02.jpg',
      'landscape_india_03.jpg',
      'landscape_india_04.jpg',
      'landscape_india_05.jpg',
      'landscape_india_06.jpg',
      'landscape_india_07.jpg',
      'landscape_ireland_01.jpg',
      'landscape_ireland_02.jpg',
      'landscape_italy_01.jpg',
      'landscape_italy_02.jpg',
      'landscape_kenia_01.jpg',
      'landscape_malaysia_01.jpg',
      'landscape_malaysia_02.jpg',
      'landscape_malaysia_03.jpg',
      'landscape_mexico_01.jpg',
      'landscape_mexico_02.jpg',
      'landscape_myanmar_01.jpg',
      'landscape_myanmar_02.jpg',
      'landscape_myanmar_03.jpg',
      'landscape_myanmar_04.jpg',
      'landscape_myanmar_05.jpg',
      'landscape_norway_01.jpg',
      'landscape_norway_02.jpg',
      'landscape_norway_03.jpg',
      'landscape_norway_04.jpg',
      'landscape_peru_01.jpg',
      'landscape_peru_02.jpg',
      'landscape_peru_03.jpg',
      'landscape_peru_04.jpg',
      'landscape_portugal_01.jpg',
      'landscape_portugal_02.jpg',
      'landscape_portugal_03.jpg',
      'landscape_spain_01.jpg',
      'landscape_tanzania_01.jpg',
      'landscape_tanzania_02.jpg',
      'landscape_tanzania_03.jpg',
      'landscape_tanzania_04.jpg',
      'landscape_thailand_01.jpg',
      'landscape_tanzania_02.jpg',
      'landscape_tanzania_03.jpg',
      'landscape_turkey_01.jpg',
      'landscape_turkey_02.jpg',
      'landscape_turkey_03.jpg',
      'landscape_vietnam_01.jpg',
      'landscape_vietnam_02.jpg',
      'landscape_vietnam_03.jpg',
    ],
    portrait: [
      'portrait_cambodia_01.jpg',
      'portrait_cambodia_02.jpg',
      'portrait_croatia_01.jpg',
      'portrait_england_01.jpg',
      'portrait_england_02.jpg',
      'portrait_france_01.jpg',
      'portrait_greece_01.jpg',
      'portrait_greece_02.jpg',
      'portrait_guatemala_01.jpg',
      'portrait_guatemala_02.jpg',
      'portrait_india_01.jpg',
      'portrait_india_02.jpg',
      'portrait_india_03.jpg',
      'portrait_india_04.jpg',
      'portrait_malaysia_01.jpg',
      'portrait_myanmar_01.jpg',
      'portrait_myanmar_02.jpg',
      'portrait_myanmar_03.jpg',
      'portrait_myanmar_04.jpg',
      'portrait_myanmar_05.jpg',
      'portrait_peru_01.jpg',
      'portrait_peru_02.jpg',
      'portrait_peru_03.jpg',
      'portrait_peru_04.jpg',
      'portrait_portugal_01.jpg',
      'portrait_tanzania_01.jpg',
      'portrait_thailand_01.jpg',
      'portrait_turkey_01.jpg',
    ],
    'square': [
      'square_cambodia_01.jpg',
      'square_cambodia_02.jpg',
      'square_croatia_01.jpg',
      'square_croatia_02.jpg',
      'square_england_01.jpg',
      'square_france_01.jpg',
      'square_france_02.jpg',
      'square_germany_01.jpg',
      'square_germany_02.jpg',
      'square_greece_01.jpg',
      'square_greece_02.jpg',
      'square_greece_03.jpg',
      'square_guatemala_01.jpg',
      'square_guatemala_02.jpg',
      'square_india_01.jpg',
      'square_india_02.jpg',
      'square_india_03.jpg',
      'square_india_04.jpg',
      'square_india_05.jpg',
      'square_india_06.jpg',
      'square_india_07.jpg',
      'square_india_08.jpg',
      'square_india_09.jpg',
      'square_india_10.jpg',
      'square_italy_01.jpg',
      'square_kenia_01.jpg',
      'square_malaysia_01.jpg',
      'square_malaysia_02.jpg',
      'square_malaysia_03.jpg',
      'square_mexico_01.jpg',
      'square_mexico_02.jpg',
      'square_myanmar_01.jpg',
      'square_myanmar_02.jpg',
      'square_myanmar_03.jpg',
      'square_myanmar_04.jpg',
      'square_myanmar_05.jpg',
      'square_myanmar_06.jpg',
      'square_norway_01.jpg',
      'square_peru_01.jpg',
      'square_peru_02.jpg',
      'square_peru_03.jpg',
      'square_peru_04.jpg',
      'square_peru_05.jpg',
      'square_peru_06.jpg',
      'square_peru_07.jpg',
      'square_portugal_01.jpg',
      'square_tanzania_01.jpg',
      'square_tanzania_02.jpg',
      'square_tanzania_03.jpg',
      'square_thailand_01.jpg',
      'square_thailand_02.jpg',
      'square_thailand_03.jpg',
      'square_thailand_04.jpg',
      'square_turkey_01.jpg',
      'square_turkey_02.jpg',
      'square_turkey_03.jpg',
      'square_vietnam_01.jpg',
      'square_vietnam_02.jpg'

    ]
  }

  return (
    <div>
      <MaxWidthWrapper>
        <div style={{height: 300}}>
          <MasonryGallery {...testData} />
        </div>
      </MaxWidthWrapper>
    </div>
  );
}
Login.displayName = "Login";

export default Login;
