import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import SideNavigationButton from "./../side-navigation/SideNavigationButton";

import styles from "./Header.styles";

const Header = ({ children, classes, date, headerChildren, weatherIds }) => {
  return (
    <div className={classes.headerWrapper}>
      <div className={classes.headerRow}>
        <SideNavigationButton />
        {headerChildren}
      </div>
      {children}
    </div>
  );
};
Header.displayName = "Header";
Header.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  headerChildren: PropTypes.node
};
Header.defaultProps = {
  classes: {}
};
export default withStyles(styles)(Header);
