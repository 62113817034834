import React, { Component } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import cx from "classnames";

import { withStyles } from "@material-ui/core";

import styles from "./ImageCarousel.styles";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class ImageCarousel extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        description: PropTypes.string
      })
    )
  };
  static defaultProps = {
    images: []
  };

  state = {
    currentImage: 0,
    imageIdsStringified: "",
    images: []
  };

  componentDidMount() {
    this.loadImages();
  }

  componentDidUpdate() {
    const { images } = this.props;
    const { imageIdsStringified } = this.state;

    const imageIdsfromProps = images.reduce(
      (idsStringified, image) => `${idsStringified}${image.id}`,
      ""
    );

    if (imageIdsStringified !== imageIdsfromProps) {
      this.loadImages();
    }
  }

  handleChangeCurrentImage = index => {
    this.setState({ currentImage: index });
  };

  loadImages = () => {
    const { images } = this.props;
    let imageIdsStringified = "";
    const imagesInState = [];

    images.forEach(image => {
      imageIdsStringified += image.id;
      try {
        // try loading the image before pushing it into the setState
        // prevent errors when images don't exist
        // eslint-disable-next-line no-unused-vars
        const loadedImage = require(`static/images/${image.path}`);
        imagesInState.push({
          ...image
        });
      } catch (e) {
        console.error(e);
      }
    });

    this.setState({
      currentImage: 0,
      imageIdsStringified,
      images: imagesInState
    });
  };

  static defaultProps = {
    classes: {}
  };

  render() {
    const { classes } = this.props;
    const { currentImage, images } = this.state;

    return images.length ? (
      <div className={classes.carouselWrapper}>
        <AutoPlaySwipeableViews
          index={currentImage}
          onChangeIndex={this.handleChangeCurrentImage}
        >
          {images.map(image => (
            <img
              key={`image_${image.id}`}
              src={require(`static/images/${image.path}`)}
              alt={image.description}
            />
          ))}
        </AutoPlaySwipeableViews>
        {
          images.length > 1 && (
            <div className={classes.navigation}>
              {images.map((image, index) => (
                <div
                  key={`step_${image.id}`}
                  className={cx(classes.step, {
                    [classes.activeStep]: index === currentImage
                  })}
                />
              ))}
            </div>
          )
        }
      </div>
    ) : null;
  }
}

export default withStyles(styles)(ImageCarousel);
