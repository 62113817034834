import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import { MealCardHalf, Category } from 'ui';

const TravelDayMeals = ({ meals = [] }) => {
    return meals.length ? (
        <Category
            id={meals.reduce((acc, { id }) => `${acc}${id}`, '')}
            title="Meals Included"
        >
            {meals.map(item => {
                return <MealCardHalf key={item.id} {...item} />;
            })}
        </Category>
    ) : null;
};
TravelDayMeals.displayName = 'TravelDayMeals';
TravelDayMeals.propTypes = {
    meals: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['BREAKFAST', 'LUNCH', 'DINNER']),
            info: PropTypes.string,
            location: PropTypes.shape({
                latitude: PropTypes.string,
                longitude: PropTypes.string
            })
        })
    )
};
TravelDayMeals.fragments = {
    meals: gql`
        fragment TravelDayMeals on MealType {
            id
            type
            info
            location {
                latitude
                longitude
            }
        }
    `
};

export default TravelDayMeals;
