import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { withStyles } from "@material-ui/core";

import { StepNavigation } from "ui";

import styles from "./TravelDay.styles";

/*
  Display the destination names for the current travel day
  and allow to navigate between the datys
*/
const TravelDayNavigation = ({
  classes,
  currentTravelDate,
  destinationNames,
  endDate,
  handleDateChange,
  startDate
}) => {
  const prevDate = moment(currentTravelDate).isAfter(startDate)
    ? moment(currentTravelDate)
        .subtract(1, "day")
        .format("YYYY-MM-DD")
    : null;
  const nextDate = moment(currentTravelDate).isBefore(endDate)
    ? moment(currentTravelDate)
        .add(1, "day")
        .format("YYYY-MM-DD")
    : null;

  return (
    <div className={classes.destinationNavigation}>
      <StepNavigation
        handleStepChange={handleDateChange}
        prev={prevDate}
        next={nextDate}
        title={destinationNames.join(" - ")}
        subtitle={
          currentTravelDate &&
          moment(currentTravelDate).format("ddd, DD. MMM YYYY")
        }
      />
    </div>
  );
};
TravelDayNavigation.displayName = "TravelDayNavigation";
TravelDayNavigation.propTypes = {
  classes: PropTypes.object,
  currentTravelDate: PropTypes.string.isRequired,
  destinationNames: PropTypes.arrayOf(PropTypes.string),
  endDate: PropTypes.string.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired
};
TravelDayNavigation.defaultProps = {
  classes: {},
  destinationNames: [],
  handleDateChange: () => {}
};

export default withStyles(styles)(TravelDayNavigation);
