import { fade } from '@material-ui/core';

export default theme => {
    const styles = {
        transportLink: {
            textDecoration: 'none',
            color: 'inherit'
        },
        transportWrapper: {
            width: 155,
            height: 175,
            flexShrink: 0,
            boxSizing: 'border-box',
            border: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: theme.spacing(0.5),
            padding: theme.spacing(1),
            marginLeft: theme.spacing(1),
            '&:nth-child(1)': {
                marginLeft: 0
            },
            textAlign: 'center'
        },
        title: {
            height: 40,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& > div': {
                width: '100%',
                fontWeight: 500,
                lineHeight: '1.3rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
            }
        },
        carTitle: {
            textOverflow: 'unset !important',
            whiteSpace: 'unset !important'
        },
        duration: {
            lineHeight: '1.3rem'
        },
        iconWrapper: {
            padding: theme.spacing(1),
            color: theme.palette.grey[400]
        },
        infoText: {
            fontSize: '0.8125rem',
            color: theme.palette.grey[400]
        },
        linksWrapper: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
        },
        link: {
            color: theme.palette.grey[400],
            fontSize: '0.8125rem',
            cursor: 'pointer',

            '& a': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                textDecoration: 'none',
                color: theme.palette.grey[400]
            },

            '& svg': {
                marginRight: theme.spacing(0.5)
            }
        }
    };

    ['error', 'warning', 'success', 'info'].forEach(color => {
        styles[color] = {
            border: `1px solid ${theme.palette[color].main}`,
            backgroundColor: fade(theme.palette[color].main, 0.05)
        };
    });

    return styles;
};
