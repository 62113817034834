import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "./SvgIcon";

export const phrasesIconPath =
  "M15,4V11H5.17L4,12.17V4H15M16,2H3A1,1 0 0,0 2,3V17L6,13H16A1,1 0 0,0 17,12V3A1,1 0 0,0 16,2M21,6H19V15H6V17A1,1 0 0,0 7,18H18L22,22V7A1,1 0 0,0 21,6Z";

const PhrasesIcon = ({ size }) => (
  <SvgIcon name="PhrasesIcon" path={phrasesIconPath} size={size} />
);
PhrasesIcon.displayName = "PhrasesIcon";
PhrasesIcon.propTypes = {
  size: PropTypes.number
};

export default PhrasesIcon;
