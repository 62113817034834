export default theme => ({
  card: {
    width: `calc(50% - ${theme.spacing(0.5)}px)`,
    height: 100,
    flexShrink: 0,
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    "&:nth-child(1)": {
      marginLeft: 0
    },
    textAlign: "center"
  },
  main: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px 0`
  },
  calculatorWrapper: {
    width: "100%",
    height: 100,
    overflow: "hidden",
    flexShrink: 0,
    flexGrow: 0,
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.spacing(0.5)
  },
  calculatorHeader: {
    width: "100%",
    height: "50px",
    boxSizing: "border-box",
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  toggleIcon: {
    margin: `0 ${theme.spacing(1)}px`,
    color: theme.palette.grey[400],
    cursor: "pointer",
    flexShrink: 0,
    flexGrow: 0
  },
  currencyTitle: {
    minWidth: "33%",
    maxWidth: "100%",
    flexShrink: 0,
    flexGrow: 1,
    textAlign: "center"
  },
  calculatorMain: {
    width: "100%",
    height: "50px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  },
  calculatorField: {
    width: "50%",
    height: "100%",
    boxSizing: "border-box",
    textAlign: "center",
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(2),
    "&:nth-child(1)": {
      borderLeft: "none"
    }
  },
  input: {
    border: "none",
    boxShadow: "none",
    padding: 0,
    margin: 0,
    fontSize: "1rem",

    "&:focus": {
      outline: "none"
    }
  }
});
