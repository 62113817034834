import React, { useContext } from "react";

import { TravelContext } from "src/context";
import { MenuIcon } from "ui/icons";

const SideNavigationButton = () => {
  const { toggleSideNavigation } = useContext(TravelContext);

  return (
    <div onClick={toggleSideNavigation}>
      <MenuIcon />
    </div>
  );
};
SideNavigationButton.displayName = "SideNavigationButton";

export default SideNavigationButton;
